/* eslint-disable react/require-default-props */
import React, { Dispatch, ReactElement, SetStateAction, useCallback, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { CloseIcon, PopupWithOverlay } from '@dataplace.ai/ui-components/atoms'
import {
  AddressList,
  AddressListItem,
  AddressText,
  Content,
  ContentHeader,
  ContentHeaderText,
  Header,
  HeaderText,
  HeaderTextWrapper,
  LoaderWrapper,
  Wrapper,
} from './RankingModal.styles'

interface RankingListObject {
  address: string
  id: number
  score: number
  locationType?: string
}

interface RankingModalProps {
  rankingList: RankingListObject[]
  currentPosition: number
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
  headerText?: string
  contentKeyText?: string
  contentValueText?: string
  trimFloat?: boolean
  additionalColumn?: boolean
}

function RankingModal({
  rankingList,
  currentPosition,
  isOpen,
  setIsOpen,
  headerText,
  contentKeyText,
  contentValueText,
  trimFloat = false,
  additionalColumn = false,
}: RankingModalProps): ReactElement {
  const { t } = useTranslation()
  const analysedAddressRef = useRef<HTMLLIElement>(null)

  const isAnalysedAddress = useCallback((index: number) => currentPosition === index + 1, [])

  const scrollToAnalysedAddress = useCallback(() => {
    analysedAddressRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    })
  }, [])

  return (
    <PopupWithOverlay
      closeOnDocumentClick={false}
      onClose={() => setIsOpen(false)}
      onOpen={scrollToAnalysedAddress}
      open={isOpen}
    >
      <Wrapper>
        {rankingList?.length
          ? (
            <>
              <Header>
                <HeaderTextWrapper>
                  <HeaderText>{headerText ?? t('placeme.network_ranking.header')}</HeaderText>
                </HeaderTextWrapper>
                <CloseIcon onClick={() => setIsOpen(false)} />
              </Header>
              <ContentHeader>
                <ContentHeaderText>{contentKeyText ?? t('placeme.network_ranking.point_address')}</ContentHeaderText>
                {additionalColumn
                  ? <ContentHeaderText padding='0 0 0 12rem'>{contentKeyText ?? t('placeme.potential_within_chain.settings.location_type')}</ContentHeaderText>
                  : null}
                <ContentHeaderText>{contentValueText ?? t('placeme.network_ranking.scoring')}</ContentHeaderText>
              </ContentHeader>
              <Content>
                <AddressList>
                  {rankingList.map((location, index) => (
                    <AddressListItem
                      key={location.address}
                      ref={isAnalysedAddress(index) ? analysedAddressRef : undefined}
                      $isAnalysedAddress={isAnalysedAddress(index)}
                    >
                      <AddressText>
                        {`${index + 1}. ${location?.address}`}
                      </AddressText>
                      {additionalColumn
                        ? <span>{t(`placeme.potential_within_chain.ranking.${location.locationType}`)}</span>
                        : null}
                      {trimFloat ? location.score.toFixed(2) : location.score}
                    </AddressListItem>
                  ))}
                </AddressList>
              </Content>
            </>
          )
          : (
            <LoaderWrapper>
              <object
                data='assets/loaders/placeme/small.svg'
                type='image/svg+xml'
                width='130'
              >
                placeme loader
              </object>
            </LoaderWrapper>
          )}
      </Wrapper>
    </PopupWithOverlay>
  )
}

export default RankingModal
