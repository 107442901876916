/* eslint-disable react/require-default-props */
import { RangeType } from '@dataplace.ai/types'
import { useAppDispatch } from 'apps/placeme/src/redux/hooks'
import React, { useState, memo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'
import { Tooltip } from '@dataplace.ai/ui-components/atoms'
import { IAnalysisState } from '../../../slice/@types/IAnalysisState'
import { ReactComponent as LockIcon } from '../../../../../../../../libs/shared/assets/src/lib/icons/lock.svg'
import { deleteTemplateAction, fetchUsersTemplatesAction } from '../../../slice/analysisSlice'
import { TemplateTile, TemplateTileModal } from '../../molecules'
import { ITemplatesData } from './templatesData'

const Wrapper = styled.div(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
    display: flex;
    flex-direction: column;
    padding: 0 2.5rem 3rem;

    >h3{
        margin: 1.5rem 0 1rem;
        color: ${palette.black};
        font-size: ${typography.main.pt_15_medium_upper.fontSize};
        font-weight: ${typography.main.pt_15_medium_upper.fontWeight};
        line-height: ${typography.main.pt_15_medium_upper.lineHeight};
        letter-spacing: ${typography.main.pt_15_medium_upper.letterSpacing};
        text-transform: ${typography.main.pt_15_medium_upper.textTransform};
    }

`
})

const GridWrapper = styled.div`
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-template-rows: auto auto;
    grid-auto-flow: row dense;
    grid-gap: 10px;
    padding: 10px;

    @media (max-width: 1230px){
        grid-template-columns: auto auto auto;
    }
    
`
const SwitcherWrapper = styled.div(({ theme }) => {
  const { palette } = theme
  return css`
    display: flex;
    border-bottom: 1px solid ${palette.light.darker};
`
})
const Switcher = styled.div<{active: boolean}>(({
  theme, active,
}) => {
  const {
    palette, typography,
  } = theme
  return css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 0.5rem;
    cursor: pointer;

    :first-of-type{
        margin-left: 0;
    }

    p{
        color:${active ? palette.product.location.main : palette.black};
        font-size: ${typography.tiny.pt_12_medium_upper.fontSize};
        font-weight: ${typography.tiny.pt_12_medium_upper.fontWeight};
        line-height: ${typography.tiny.pt_12_medium_upper.lineHeight};
        letter-spacing: ${typography.tiny.pt_12_medium_upper.letterSpacing};
        text-transform: ${typography.tiny.pt_12_medium_upper.textTransform};
        padding:0 1rem 1rem;
    }
    
    span{
        width: 100%;
        height: 3px;
        ${active && css`
            background: ${palette.product.location.main};
            border-radius: 6px 6px 0px 0px;
        `}
    }
 `
})

const DefaultIcon = styled.div(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      border-radius: 100px;
      background-color: ${palette.blue};
      color: ${palette.light.white};
      font-size: ${typography.tiny.pt_12_medium_upper.fontSize};
      font-weight: ${typography.tiny.pt_12_medium_upper.fontWeight};
      line-height: ${typography.tiny.pt_12_medium_upper.lineHeight};
      letter-spacing: ${typography.tiny.pt_12_medium_upper.letterSpacing};
      text-transform: ${typography.tiny.pt_12_medium_upper.textTransform};
    `
})

const ComparedLocationChosenWrapper = styled.div(({ theme }) => {
  const {
    palette, typography,
  } = theme

  return css`
    display: flex;
    align-items: center;
    >h3{
      margin: 1.5rem .5rem 1rem;
      color: ${palette.black};
      font-size: ${typography.main.pt_15_medium_upper.fontSize};
      font-weight: ${typography.main.pt_15_medium_upper.fontWeight};
      line-height: ${typography.main.pt_15_medium_upper.lineHeight};
      letter-spacing: ${typography.main.pt_15_medium_upper.letterSpacing};
      text-transform: ${typography.main.pt_15_medium_upper.textTransform};
    }

    >div{
      position: relative;
      top: 5px;
    }
`
})

interface IAnalyseTemplates {
  token: string
  defaultTemplatesData: ITemplatesData[]
  usersTemplatesData?: ITemplatesData[]
  workspaceName: string
  comparedLocation?: IAnalysisState['comparedLocation']
  setTilesNextToLoad: React.Dispatch<React.SetStateAction<{
    loading: boolean
    error: string
    tile: string
    chosenRange: {
      type: RangeType
      value: number
    }
  }[] | undefined>>

}

export const AnalyseTemplates = memo(({
  workspaceName, setTilesNextToLoad, defaultTemplatesData, usersTemplatesData, token, comparedLocation,
}:IAnalyseTemplates): JSX.Element => {
  // constants
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const [shownTemplates, setShownTemplates] = useState(defaultTemplatesData)
  const [switcherType, setSwitcherType] = useState('defaults')

  const handleSwitcherClick = (type:'users'|'defaults') => {
    if (type === 'users' && usersTemplatesData) {
      setShownTemplates(usersTemplatesData)
      setSwitcherType('users')
    } else {
      setShownTemplates(defaultTemplatesData)
      setSwitcherType('defaults')
    }
  }

  const handleDeleteTemplate = async (templateId: string) : Promise<void> => {
    if (token.length && templateId.length) {
      try {
        await dispatch(deleteTemplateAction(templateId))
      }
      finally {
        dispatch(fetchUsersTemplatesAction())
      }
    }
  }

  // refreshes data after delete template
  useEffect(() => {
    if (usersTemplatesData?.length) {
      setSwitcherType('users')
      setShownTemplates(usersTemplatesData)
    } else {
      setShownTemplates(defaultTemplatesData)
      setSwitcherType('defaults')
    }
  }, [usersTemplatesData])

  return (
    <Wrapper>

      {comparedLocation?.location?.address
        ? (
          <ComparedLocationChosenWrapper>
            <h3>{t('placeme.templates.header')}</h3>
            <Tooltip
              content={t('placeme.templates.compare_location.desc')}
              maxWidth='300px'
              position='right center'
            >
              <LockIcon />
            </Tooltip>
          </ComparedLocationChosenWrapper>
        )
        : (
          <>
            {/* po podłączeniu backu (get na szablony usera) tutaj powinien być check nie na dummy
      tylko na szablony usera zapisane w storze */}
            {!!usersTemplatesData?.length
      && (
        <SwitcherWrapper>
          <Switcher
            active={switcherType === 'users'}
            onClick={() => handleSwitcherClick('users')}
          >
            <p>
              {t('placeme.templates.my_templates')}
              {' '}
              (
              {usersTemplatesData?.length}
              )
            </p>
            <span />
          </Switcher>
          <Switcher
            active={switcherType === 'defaults'}
            onClick={() => handleSwitcherClick('defaults')}
          >
            <p>
              {t('placeme.templates.default_templates')}
              {' '}
              (
              {defaultTemplatesData?.length}
              )
            </p>
            <span />
          </Switcher>
        </SwitcherWrapper>
      )}
            <h3>{t('placeme.templates.header')}</h3>
            <GridWrapper>
              { shownTemplates?.map(template => (
                <TemplateTileModal
                  key={template?.id}
                  icon={template?.icon || <DefaultIcon>{workspaceName?.slice(0, 2).toString()}</DefaultIcon>}
                  setTilesNextToLoad={setTilesNextToLoad}
                  style={template?.tiles?.length > 5
                    ? {
                      gridColumn: 'auto / span 2',
                    }
                    : undefined}
                  templateTiles={template?.tiles}
                  title={template?.title}
                  trigger={(
                    <TemplateTile
                      handleDeleteTemplate={handleDeleteTemplate}
                      icon={template?.icon || <DefaultIcon>{workspaceName?.slice(0, 2).toString()}</DefaultIcon>}
                      isUsersTemplate={switcherType === 'users'}
                      templateId={template?.id}
                      templatesTiles={template?.tiles}
                      title={template?.title}
                    />
                  )}
                />

              ))}
            </GridWrapper>
          </>
        )}
    </Wrapper>

  )
})

