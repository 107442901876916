/* eslint-disable max-lines */
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import styled, { css } from 'styled-components'
import Popup from 'reactjs-popup'
import * as Yup from 'yup'
import { defaultTheme } from '@dataplace.ai/ui-components/themes/defaultTheme'
import { useTranslation } from 'react-i18next'
import { Formik } from 'formik'
import { Button, TextButton } from '@dataplace.ai/ui-components/atoms'
import {
  Form,
  FormSection,
  TextInput,
  Select,
} from '@dataplace.ai/ui-components/molecules'
import { useSelector } from 'react-redux'
import { fetchAnalysesAndReports } from 'apps/placeme/src/features/AnalyseAndReports/slice/analysesAndReportsSlice'
import { IAnalysesData } from 'apps/placeme/src/features/AnalyseAndReports/components/organisms/AnalyseAndReportBody/@types/IAnalysesData'
import { ENDPOINTS } from 'apps/placeme/src/constants/endpoints'
import { useAppDispatch } from 'apps/placeme/src/redux/hooks'
import { AnalyticsContext, AuthContext } from '@dataplace.ai/features'
import { getAxios } from '@dataplace.ai/functions/utils/axios'
import { getWorkspaceIdFromLocalStorage, redirectToReports } from '@dataplace.ai/functions/utils'
import { Checkbox } from '@dataplace.ai/ui-components/organisms/FilterableSortableTable/atoms/CheckboxFilter/components/Checkbox'
import { useSignal } from '@preact/signals-react'
import { useSaveLocation, useFeatureAccess } from '@dataplace.ai/functions/hooks'
import { featureAccessIds } from 'apps/placeme/src/constants/constants'
import { ReactComponent as CloseIcon } from '../../../../../../../../libs/shared/assets/src/lib/icons/dataplace/add.svg'
import { ReactComponent as FolderIcon } from '../../../../../../../../libs/shared/assets/src/lib/icons/profile/folder.svg'
import { initialValues } from './@constants/initialValues'
import { RootState } from '../../../../../redux/store'
import { exitFromAnalyseAction } from '../../../functions/exitFromAnalyse'

const Trigger = styled.div(
  () => css`
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  `,
)
const Wrapper = styled.div(({ theme }) => {
  const {
    palette, corners,
  } = theme
  return css`
    border-radius: ${corners.default.borderRadius};
    background-color: ${palette.light.white};
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2.5rem 2.5rem 1.5rem;
    min-width: 460px;
  `
})
const TopWrapper = styled.div(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    h3 {
      color: ${palette.black};
      font-size: ${typography.big.pt_28_semibold.fontSize};
      font-weight: ${typography.big.pt_28_semibold.fontWeight};
      line-height: ${typography.big.pt_28_semibold.lineHeight};
    }
  `
})
const Description = styled.h2(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
    color: ${palette.black};
    font-size: ${typography.main.pt_15_regular.fontSize};
    font-weight: ${typography.main.pt_15_regular.fontWeight};
    line-height: ${typography.main.pt_15_regular.lineHeight};
  `
})
const BottomWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 2rem;
`
const Header = styled.span(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
    margin-bottom: 0.5rem;
    color: ${palette.black};
    font-size: ${typography.main.pt_15_medium.fontSize};
    font-weight: ${typography.main.pt_15_medium.fontWeight};
    line-height: ${typography.main.pt_15_medium.lineHeight};
  `
})

const Content = styled.span(({ theme }) => {
  const {
    palette, typography,
  } = theme

  return css`
    margin-bottom: 0.5rem;
    color: ${palette.results.red.dark};
    font-size: ${typography.tiny.pt_12_regular.fontSize};
    font-weight: ${typography.tiny.pt_12_regular.fontWeight};
    line-height: ${typography.tiny.pt_12_regular.lineHeight};
  `
})

const CheckboxWrapper = styled.div`
  display: flex;
  margin-top: 1rem;
`
export interface SaveAnalyseModalProps {
  trigger: JSX.Element
  handleDelete?: () => void
  close?: () => void
  analyseId: string
  wasLocationSaved?: boolean
}

interface FolderProps {
  id: string;
  name: string
}

export const SaveAnalyseModal = ({
  trigger, analyseId, wasLocationSaved,
}: SaveAnalyseModalProps): JSX.Element => {
  // constants
  const { t } = useTranslation()
  const authContext = useContext(AuthContext)
  const { analytics } = useContext(AnalyticsContext)
  const address = useSelector((state: RootState) => state.location?.value?.address || '')
  const { userType } = useSelector((state: RootState) => state.location)
  const { analysisDbInfo } = useSelector((state: RootState) => state.analysis)
  const { hasFeatureAccess } = useFeatureAccess()
  const hasKanbanAccess = hasFeatureAccess(featureAccessIds.kanban)
  const { saveLocation } = useSaveLocation()
  const {
    loading, value,
  } = useSelector(
    (state: RootState) => state.analysesAndReports.data,
  )
  const dispatch = useAppDispatch()
  const projectTypes = [
    'placeme.save_analyse.private',
    'placeme.save_analyse.public',
  ]

  // the initial name of the analysis is the address being analyzed
  initialValues.analyseName = address || ''

  // states
  const [token, setToken] = useState('')
  const [open, setOpen] = useState(false)
  const [newProject, setNewProject] = useState(false)
  const [existingProjects, setProjects] = useState<FolderProps[]>([])
  const [redirect, setRedirect] = useState(false)
  const [isFolderAlreadyExist, setFolderExist] = useState(false)
  const signalFoldersRequested = useSignal(false)
  const [shouldSaveLocation, setShouldSaveLocation] = useState(false)

  // functions
  const closeModal = () => setOpen(false)
  const openModal = () => setOpen(true)
  const addNewProject = () => {
    setNewProject(!newProject)
  }

  const canSaveLocation = useMemo(() => (
    hasKanbanAccess && !wasLocationSaved && !analysisDbInfo?.locationExists && !analysisDbInfo?.locationId
  ), [hasKanbanAccess, analysisDbInfo?.locationExists, analysisDbInfo?.locationId, userType, wasLocationSaved])

  const data: IAnalysesData = value as IAnalysesData

  const fetchProjectId = (projectName: string) => {
    let projectId = ''
    if (existingProjects.length) {
      existingProjects.forEach((project) => {
        if (project?.name === projectName) {
          projectId = project.id
        }
      })
    }

    return projectId
  }

  const folderNames = () => {
    const returnProjects: string[] = []
    if (existingProjects.length) {
      existingProjects.forEach((project) => {
        if (project !== null && project?.name) {
          returnProjects.push(project.name)
        }
      })
    }
    return returnProjects
  }

  const saveAnalyse = useCallback(
    async (
      analyseName: string,
      projectType: string,
      projectName?: string,
      projectId?: string, // folder id
      analyseId?: string,
      reports?: (string[] | null),
      locationId?: string | undefined,
    ) => {
      let body
      if (reports) { reports.push(analyseId || '') }

      if (projectType || analyseName) {
        const status = projectType.toLowerCase().includes('private')
          ? 'private'
          : 'view'

        body = {
          status,
          name: analyseName,
          locationId: canSaveLocation ? locationId : undefined,
        }
        const axiosInstance = await getAxios()
        await axiosInstance.patch(
          `${ENDPOINTS.CREATE_DELETE_SHARE_ANALYSE}/${analyseId}`,
          body,
        )
      }

      if (projectId) {
        body = {
          reports: [analyseId],
        }
        let saveData
        const axiosInstance = await getAxios({
          errCallbackFn: (e) => {
            saveData = {
              loading: false,
              error: e,
              value: null,
            }
            if (e.message === 'status.err_folder_already_exists') {
              setFolderExist(true)
            }
          },
        })
        const response = await axiosInstance.patch(
          `${ENDPOINTS.CREATE_GET_ADD_DELETE_FOLDER}/${projectId}`,
          body,
        )
        if (response) {
          saveData = {
            loading: false,
            error: '',
            value: response.data,
          }
          if (saveData.value) {
            analytics?.track('Analysis Saved')
            closeModal()
            setRedirect(true)
          }
        }
      }

      if (projectName) {
        body = {
          workspaceId: getWorkspaceIdFromLocalStorage(),
          folderName: projectName,
        }
        let saveData
        const axiosInstance = await getAxios({
          errCallbackFn: (e) => {
            saveData = {
              loading: false,
              error: e,
              value: null,
            }
            if (e.message === 'status.err_folder_already_exists') {
              setFolderExist(true)
            }
          },
        })
        const response = await axiosInstance.post(
          `${ENDPOINTS.CREATE_GET_ADD_DELETE_FOLDER}`,
          body,
        )
        if (response) {
          saveData = {
            loading: false,
            error: '',
            value: response.data,
          }
          analytics?.track('New Folder Created')
          body = {
            reports: [analyseId],
          }
          const newAxiosInstance = await getAxios({
            errCallbackFn: (e) => {
              saveData = {
                loading: false,
                error: e,
                value: null,
              }
              if (e.message === 'status.err_folder_already_exists') {
                setFolderExist(true)
              }
            },
          })
          const newResponse = await newAxiosInstance.patch(
            `${ENDPOINTS.CREATE_GET_ADD_DELETE_FOLDER}/${saveData.value.id}`,
            body,
          )

          if (newResponse) {
            saveData = {
              loading: false,
              error: '',
              value: newResponse.data,
            }
            if (saveData.value) {
              analytics?.track('Analysis Saved')
              closeModal()
              setRedirect(true)
            }
          }
        }
      }
    },
    [token, canSaveLocation],
  )

  const getFolders = async () => {
    const axiosInstance = await getAxios()
    const response = await axiosInstance.get<FolderProps[]>(ENDPOINTS.FOLDER)
    if (!response.data) return
    const folderList: FolderProps[] = response.data.filter(folder => folder.id !== 'whitespots')
    setProjects(folderList)
  }

  const fetchExistingReports = (folderId?: string) => {
    if (data) {
      const reports = data.folders.map((folder) =>
        (!folder.folderId.toLocaleLowerCase().includes('all')
          ? {
            folderId: folder.folderId,
            reports: folder.values.map((report =>
              report.id
            )),
          }
          : null))
      return reports.find((elem) => elem?.folderId === folderId)?.reports
    }
    return []
  }

  const saveLocationAndGetItsId = useCallback(async () => {
    if (!canSaveLocation || !shouldSaveLocation || !analysisDbInfo) return

    const data = await saveLocation({
      address: analysisDbInfo.address,
      lat: analysisDbInfo.locationLat,
      lng: analysisDbInfo.locationLng,
    })
    return data?.potential.find(loc => loc.address === analysisDbInfo.address)?.id
  }, [analysisDbInfo, canSaveLocation, shouldSaveLocation])

  const handleSubmit = async (e: {
    analyseName: string
    projectName: string
    projectType: string
    newProjectName: string
  }) => {
    const status = e.projectType.toLowerCase().includes('private')
      ? 'private'
      : 'view'

    const savedLocationId = await saveLocationAndGetItsId()

    if (!e.newProjectName.length) {
      const projectId = fetchProjectId(e.projectName)
      saveAnalyse(
        e.analyseName,
        status,
        undefined,
        projectId,
        analyseId,
        fetchExistingReports(projectId),
        savedLocationId,
      )
    } else {
      saveAnalyse(e.analyseName, status, e.newProjectName,
        undefined, analyseId, fetchExistingReports(undefined), savedLocationId)
    }
  }

  // hooks
  useEffect(() => {
    authContext.userData?.user?.getIdToken()?.then((response) => {
      setToken(response)
    })
  }, [authContext])

  useEffect(() => {
    if (token.length) {
      dispatch(fetchAnalysesAndReports())
    }
  }, [token])

  useEffect(() => {
    if (data && !signalFoldersRequested.value) {
      signalFoldersRequested.value = true
      getFolders()
    }
  }, [loading])

  if (redirect) {
    exitFromAnalyseAction(dispatch, false, analyseId)
    redirectToReports()
  }
  return (
    <>
      <Popup
        modal
        onClose={closeModal}
        onOpen={openModal}
        open={open}
        overlayStyle={defaultTheme.overlay}
        trigger={<Trigger>{trigger}</Trigger>}
      >
        <Wrapper>
          <TopWrapper>
            <h3>{t('placeme.save_analyse.save_analyse_as')}</h3>
            <CloseIcon
              height='18'
              onClick={closeModal}
              style={{
                transform: 'rotate(45deg)',
                cursor: 'pointer',
              }}
              width='18'
            />
          </TopWrapper>
          <Formik
            initialValues={initialValues}
            onSubmit={(e) => {
              handleSubmit(e)
            }}
            validationSchema={Yup.object().shape({
              analyseName: Yup.string().max(100),
              newProjectName: (folderNames().length === 0 || newProject)
                ? Yup.string().max(50)
                  .notOneOf(folderNames(), t('placeme.create_folder.name_is_taken'))
                  .required(t('generic.field_is_required'))
                : Yup.string().max(50)
                  .notOneOf(folderNames(), t('placeme.create_folder.name_is_taken')),
              projectName: (folderNames().length > 0 && !newProject)
                ? Yup.string().max(50)
                  .required(t('generic.field_is_required'))
                : Yup.string().max(50),
            })}
          >
            {({
              errors,
              handleBlur,
              handleSubmit,
              touched,
              values,
              setFieldValue,
              isValid,
            }) => (
              <Form
                centered
                onSubmit={handleSubmit}
              >
                <FormSection>
                  <div>
                    <TextInput
                      error={
                        touched.analyseName && errors.analyseName
                          ? errors.analyseName
                          : undefined
                      }
                      label={t('placeme.save_analyse.analyse_name')}
                      margin='0 0 2rem'
                      name='analyseName'
                      onBlur={handleBlur}
                      onChange={setFieldValue}
                      placeholder={t(
                        'placeme.save_analyse.type_analyse_name',
                      )}
                      reset
                      value={values.analyseName}
                    />
                    <Select
                      label={t('generic.save_analyse')}
                      name='projectType'
                      onChange={setFieldValue}
                      options={projectTypes}
                      placeholder={t(
                        'placeme.save_analyse.choose_project_type',
                      )}
                      selected={t(values.projectType)}
                    />
                    {folderNames().length === 0 && (
                      <>
                        <Description>
                          {t('placeme.save_analyse.create_first_project')}
                        </Description>
                        <TextInput
                          error={
                            touched.newProjectName && errors.newProjectName
                              ? errors.newProjectName
                              : undefined
                          }
                          icon={<FolderIcon />}
                          label={t('placeme.save_analyse.project_name')}
                          margin='10px 0 0'
                          name='newProjectName'
                          onBlur={handleBlur}
                          onChange={setFieldValue}
                          placeholder={t(
                            'placeme.save_analyse.type_project_name',
                          )}
                          reset
                          value={values.newProjectName}
                        />
                      </>
                    )}
                    {folderNames().length > 0 && !newProject && (
                      <>
                        <Description>
                          {t('placeme.save_analyse.choose_project')}
                        </Description>
                        <Select
                          error={
                            touched.projectName && errors.projectName
                              ? errors.projectName
                              : undefined
                          }
                          icon={<FolderIcon />}
                          label={t('placeme.save_analyse.project_name')}
                          maxHeight='155px'
                          name='projectName'
                          onChange={setFieldValue}
                          options={folderNames()}
                          placeholder={t(
                            'placeme.save_analyse.choose_project',
                          )}
                          selected={values.projectName}
                          withScroll
                        />
                        <div
                          style={{
                            marginTop: '15px',
                          }}
                        >
                          <TextButton
                            onClick={addNewProject}
                            type='button'
                          >
                            <p
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              <CloseIcon
                                style={{
                                  marginRight: '5px',
                                }}
                              />
                              {t('placeme.save_analyse.add_new_project')}
                            </p>
                          </TextButton>
                        </div>
                      </>
                    )}
                    {newProject && (
                      <>
                        <Description>
                          {t('placeme.save_analyse.add_new_project')}
                        </Description>
                        <TextInput
                          error={
                            touched.newProjectName && errors.newProjectName
                              ? errors.newProjectName
                              : undefined
                          }
                          icon={<FolderIcon />}
                          label={t('placeme.save_analyse.project_name')}
                          margin='10px 0'
                          name='newProjectName'
                          onBlur={handleBlur}
                          onChange={setFieldValue}
                          placeholder={t(
                            'placeme.save_analyse.type_project_name',
                          )}
                          reset
                          value={values.newProjectName}
                        />
                        <TextButton onClick={addNewProject}>
                          <p>{t('placeme.save_analyse.choose_project')}</p>
                        </TextButton>
                      </>
                    )}
                    {canSaveLocation
                      ? (
                        <CheckboxWrapper>
                          <Checkbox
                            checked={shouldSaveLocation}
                            label={t('placeme.save_analyse.save_location')}
                            onChange={() => setShouldSaveLocation(!shouldSaveLocation)}
                          />
                        </CheckboxWrapper>
                      )
                      : null}
                  </div>
                  <BottomWrapper>
                    <TextButton
                      onClick={closeModal}
                      type='button'
                    >
                      <p>{t('generic.cancel')}</p>
                    </TextButton>
                    <Button
                      disabled={newProject && !isValid}
                      margin='0 0 0 20px'
                      type='submit'
                    >
                      {t('generic.save')}
                    </Button>
                  </BottomWrapper>
                </FormSection>
              </Form>
            )}
          </Formik>
        </Wrapper>
      </Popup>
      {isFolderAlreadyExist && (
        <Popup
          modal
          open={isFolderAlreadyExist}
          overlayStyle={defaultTheme.overlay}
        >
          <Wrapper>
            <Header>{t('placeme.save_analyse.folder_name_exist')}</Header>
            <Content>
              {t('placeme.save_analyse.folder_name_exist.description')}
            </Content>
            <TextButton
              onClick={() => {
                setFolderExist(false)
                openModal()
              }}
            >
              <p>{t('generic.ok')}</p>
            </TextButton>
          </Wrapper>
        </Popup>
      )}
    </>
  )
}
