import React, { Dispatch, SetStateAction, useMemo, useState } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import {
  LinkWithIconCheckOnMap,
  TitleFormSectionSubTitle,
  MapTile,
  TileFormParagraph,
  IMapLocationProps,
} from '@dataplace.ai/ui-components/atoms'
import { useTranslation } from 'react-i18next'
import { AddMapView, MapVisualizationModal } from '@dataplace.ai/ui-components/molecules'
import { RootState } from 'apps/placeme/src/redux/store'
import { ComparedLocationHeader } from '@placeme/components/atoms'
import { BasicLayers, GeojsonLayers, WMSLayers, FeatureCollectionLayers } from '@dataplace.ai/ui-components/atoms/MapTile/@types/LayersTypes'
import { ILocation } from '@dataplace.ai/types'
import { TileSectionIds } from '@dataplace.ai/constants'
import { getTileInfo } from 'apps/placeme/src/functions/getTileInfo'

const MapWrapper = styled.div`
  margin: 1rem 0;
  width: 100%;
  height: 300px;
`

const StyledTitleFormSectionSubTitle = styled(TitleFormSectionSubTitle)`
  margin-bottom: 0;
`

interface TimeSelectorProps {
  layers?: (BasicLayers | GeojsonLayers | WMSLayers | FeatureCollectionLayers)[],
  comparedLayers?: (BasicLayers | GeojsonLayers | WMSLayers | FeatureCollectionLayers)[],
  setMapLocation?: Dispatch<SetStateAction<IMapLocationProps>> | (() => void)
  setIsComparedMapVisible: Dispatch<SetStateAction<boolean>>
  isComparedMapVisible: boolean
  setComparedMapLocation?: Dispatch<SetStateAction<IMapLocationProps>> | (() => void)
  comparedLocation?: {
    location: ILocation
    alreadyGenerated: boolean
    generatedFromNow: boolean
  }
}

export const Maps: React.FC<TimeSelectorProps> = ({
  layers,
  comparedLayers,
  setMapLocation,
  setIsComparedMapVisible,
  comparedLocation,
  isComparedMapVisible,
  setComparedMapLocation,
}) => {
  const { t } = useTranslation()
  const { value } = useSelector((state: RootState) => state.location)
  const { values } = useSelector((state: RootState) => state.analysis)

  const tileInfo = useMemo(() =>
    getTileInfo('overlapping_ranges', TileSectionIds.INHABITANTS_AND_VISITORS, values), [values])

  const [isMapVisible, setIsMapVisible] = useState(false)
  const [isMapDisplayed, setIsMapDisplayed] = useState(false)
  const [isComparedMapDisplayed, setIsComparedMapDisplayed] = useState(false)

  const handleMapOpen = (isComparedLocation: boolean) => {
    if (!isComparedLocation) setIsMapDisplayed(!isMapDisplayed)
    else setIsComparedMapDisplayed(!isComparedMapDisplayed)
  }

  const handleAddMap = (compared: boolean) => {
    if (compared) setIsComparedMapVisible(true)
    else setIsMapVisible(true)
  }

  return (
    <>
      {comparedLayers
        ? (
          <ComparedLocationHeader>
            <h5>{t('placeme.municipality_population.compared_location.header_1')}</h5>
            {' '}
            <span>{value?.address}</span>
          </ComparedLocationHeader>
        )
        : null}
      <StyledTitleFormSectionSubTitle>
        <span>{t('placeme.time_selector.ranges_on_the_map')}</span>
        <LinkWithIconCheckOnMap onClick={() => handleMapOpen(false)} />
      </StyledTitleFormSectionSubTitle>
      <TileFormParagraph>
        {t('placeme.overlapping_ranges.map_description.on_click')}
      </TileFormParagraph>
      {(!isMapVisible)
        ? (
          <AddMapView
            buttonValue={0}
            description='placeme.add_traffic_visualisation_map.description'
            onChange={() => handleAddMap(false)}
            title='placeme.add_access_range_map.title'
          />
        )
        : (
          <MapWrapper>
            <MapTile
              dragging
              height='100%'
              layers={layers || undefined}
              location={value}
              mapId='example-map-data-tile'
              pinDisplayed
              popupHeading={`${t('generic.chosen_location')}:`}
              popupParagraph={value?.address}
              setMapLocation={setMapLocation}
              showScaleControl
              width='100%'
              zoom={12}
              zoomControl
            />
          </MapWrapper>
        )}
      {comparedLayers
        ? (
          <>
            <ComparedLocationHeader second>
              <h5>{t('placeme.municipality_population.compared_location.header_2')}</h5>
              {' '}
              <span>{comparedLocation?.location?.address}</span>
            </ComparedLocationHeader>
            <StyledTitleFormSectionSubTitle>
              <span>{t('placeme.time_selector.ranges_on_the_map')}</span>
              <LinkWithIconCheckOnMap onClick={() => handleMapOpen(true)} />
            </StyledTitleFormSectionSubTitle>
            <TileFormParagraph>
              {t('placeme.access_range_tile.map_description.on_click')}
            </TileFormParagraph>
            {(!isComparedMapVisible)
              ? (
                <AddMapView
                  buttonValue={0}
                  description='placeme.add_traffic_visualisation_map.description'
                  onChange={() => handleAddMap(true)}
                  title='placeme.add_access_range_map.title'
                />
              )
              : (
                <MapWrapper>
                  <MapTile
                    dragging
                    height='100%'
                    layers={comparedLayers || undefined}
                    location={comparedLocation?.location}
                    mapId='example-map-data-tile'
                    pinDisplayed
                    popupHeading={`${t('generic.chosen_location')}:`}
                    popupParagraph={comparedLocation?.location?.address}
                    setMapLocation={setComparedMapLocation}
                    showScaleControl
                    width='100%'
                    zoom={12}
                    zoomControl
                  />
                </MapWrapper>
              )}
          </>
        )
        : null}
      {isMapDisplayed
        ? (
          <MapVisualizationModal
            isDisplayed={isMapDisplayed}
            layers={layers || undefined}
            location={value}
            mapId={`time-select-access-map-${tileInfo?.chosenRange?.catchmentId}`}
            setIsDisplay={setIsMapDisplayed}
            zoom={12}
          />
        )
        : null}
      {isComparedMapDisplayed
        ? (
          <MapVisualizationModal
            isDisplayed={isComparedMapDisplayed}
            layers={comparedLayers || undefined}
            location={comparedLocation?.location}
            mapId={`time-select-access-map-${tileInfo?.chosenRange?.catchmentId}_compared`}
            setIsDisplay={setIsComparedMapDisplayed}
            zoom={12}
          />
        )
        : null}
    </>
  )
}
