import React, { ReactElement } from 'react'
import { Radar } from 'react-chartjs-2'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'
import { IRadarChartProps } from './@types/IRadarChartData'
import CheckboxButton from './components/CheckboxButton'
import useRadarChart from './hooks/useRadarChart'

const RadarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.875rem 0;
  gap: 1rem;
`

const LabelWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 1.875rem;
`

const Label = styled.span(({
  theme: {
    palette,
    typography,
  },
}) => css`
  color: ${palette.black};
  font-size: ${typography.tiny.pt_12_regular.fontSize};
  font-weight: ${typography.tiny.pt_12_regular.fontWeight};
  line-height: ${typography.tiny.pt_12_regular.lineHeight};
`)

const ButtonsSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

const RADAR_OPTIONS = {
  legend: {
    display: false,
  },
  plugins: {
    datalabels: {
      display: false,
    },
  },
  tooltips: {
    enabled: false,
  },
  scale: {
    pointLabels: {
      fontSize: 11,
    },
    ticks: {
      beginAtZero: false,
      stepSize: 5,
      min: -10,
      max: 10,
    },
  },
}

export function RadarChart({
  similarLocations,
  analysedAddress,
}: IRadarChartProps): ReactElement {
  const { t } = useTranslation()
  const {
    radarData,
    updateDataset,
    toggleDatasetsVisibility,
    visibleAllDatasets,
  } = useRadarChart({
    similarLocations,
    analysedAddress,
  })

  return (
    <RadarWrapper>
      <Radar
        data={radarData}
        options={RADAR_OPTIONS}
      />
      <LabelWrapper>
        <Label>{t('placeme.sales_potential_tile.radar.legend.key')}</Label>
        <Label>
          {`${t('placeme.sales_potential_tile.radar.legend.value.lowest')}: -10  ${t('placeme.sales_potential_tile.radar.legend.value.highest')}: 10`}
        </Label>
      </LabelWrapper>
      <ButtonsSection>
        <CheckboxButton
          buttonText={visibleAllDatasets ? t('generic.show_all_feminine') : t('generic.hide_all_feminine')}
          checked={radarData.datasets.every(set => set.hidden === false)}
          onClick={toggleDatasetsVisibility}
        />
        {radarData.datasets.map((set, index) => (
          <CheckboxButton
            key={set.label}
            buttonText={set.label}
            checked={!set.hidden}
            indicatorColor={set.borderColor}
            onClick={() => updateDataset(index)}
          />
        ))}
      </ButtonsSection>
    </RadarWrapper>
  )
}
