/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable react/require-default-props */
import React, { Dispatch, SetStateAction, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'
import { getAxios } from '@dataplace.ai/functions/utils/axios'
import { MiniLoader, Tooltip } from '@dataplace.ai/ui-components/atoms'
import { getLanguage } from '@dataplace.ai/functions/utils'
import { ENDPOINTS } from 'apps/placeme/src/constants/endpoints'
import { DownloadButton } from '../AnalysePageHeader.styles'
import { ReactComponent as DownloadIcon } from '../../../../../../../../../libs/shared/assets/src/lib/icons/dataplace/download.svg'
import { TOOLTIP_MAX_WIDTH } from '../../Tiles/Surroundings/Investments/utils/constants'

const StyledAnchor = styled.a(({ theme: { palette } }) => css`
  color: ${palette.light.white};
  outline: none;

  :visited {
    color: ${palette.light.white};
    text-decoration-color: ${palette.light.white};
  }
`)

interface SharedExcelProps {
  reportId: string
  isExcelLoading: boolean
  setIsExcelLoading: Dispatch<SetStateAction<boolean>>
}

interface DownloadComponentProps extends SharedExcelProps {
  disabled?: boolean
}

function DownloadComponent({
  disabled = false, reportId, isExcelLoading, setIsExcelLoading,
}: DownloadComponentProps) {
  const { t } = useTranslation()

  const downloadExcel = useCallback(async () => {
    setIsExcelLoading(true)
    const axiosInstance = await getAxios({
      errCallbackFn: () => setIsExcelLoading(false),
    })
    const response = await axiosInstance.post(ENDPOINTS.EXCEL, {
      reportId,
    },
    {
      responseType: 'json',
    })
    if (response) {
      const base64Data = response.data.file
      const binaryString = window.atob(base64Data)
      const bytes = new Uint8Array(
        Array.from(binaryString).map(char => char.charCodeAt(0)),
      )
      const link = document.createElement('a')
      const mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      const blob = new Blob([bytes], {
        type: mimeType,
      })
      const url = window.URL.createObjectURL(blob)
      link.href = url
      link.setAttribute('download', response.data.fileName)
      link.target = '_blank'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      window.URL.revokeObjectURL(url)
      setIsExcelLoading(false)
    }
  }, [reportId])

  return (
    <DownloadButton
      disabled={disabled || isExcelLoading}
      onClick={downloadExcel}
      type='button'
    >
      <DownloadIcon />
      {isExcelLoading
        ? (
          <MiniLoader $size='1rem' />
        )
        : <span>{t('generic.download_excel')}</span>}
    </DownloadButton>
  )
}

export interface DownloadExcelButtonProps extends SharedExcelProps {
  hasExcelAccess?: boolean
  downloadDisabled: boolean
  isTrial: boolean
}

function DownloadExcelButton({
  hasExcelAccess = false, downloadDisabled, isTrial, isExcelLoading, reportId, setIsExcelLoading,
}: DownloadExcelButtonProps) {
  const { t } = useTranslation()
  const contactHref = useMemo(() => `https://dataplace.ai/${getLanguage()}/contact/`, [getLanguage])

  const isExcelDownloadDisabled = useMemo(() => downloadDisabled || !hasExcelAccess, [downloadDisabled, hasExcelAccess])

  return (isExcelDownloadDisabled || isTrial
    ? (
      <Tooltip
        content={
          hasExcelAccess
            ? t(`placeme.download_button.disabled${isTrial ? '_for_trial' : ''}`)
            : (
              <>
                {t('placeme.download_button.disabled_excel_1')}
                <StyledAnchor
                  href={contactHref}
                  rel='noopener'
                  target='_blank'
                >
                  {t('placeme.download_button.disabled_excel_2')}
                </StyledAnchor>
              </>
            )
        }
        maxWidth={TOOLTIP_MAX_WIDTH}
        position='left top'
      >
        <DownloadComponent
          disabled={isExcelDownloadDisabled}
          isExcelLoading={isExcelLoading}
          reportId={reportId}
          setIsExcelLoading={setIsExcelLoading}
        />
      </Tooltip>
    )
    : (
      <DownloadComponent
        disabled={isExcelDownloadDisabled}
        isExcelLoading={isExcelLoading}
        reportId={reportId}
        setIsExcelLoading={setIsExcelLoading}
      />
    )
  )
}

export default DownloadExcelButton
