import { IRange } from '@dataplace.ai/types'
import { BackButton, Button, CloseIcon, TextButton } from '@dataplace.ai/ui-components/atoms'
import { handleMaxRanges } from 'apps/placeme/src/functions/handleMaxRanges'
import { RootState } from 'apps/placeme/src/redux/store'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import { defaultMaxRanges } from 'apps/placeme/src/features/Analyse/utils/constants'
import { RangeTile } from '../../../../organisms'

const Wrapper = styled.div(({ theme }) => {
  const {
    palette, corners, typography,
  } = theme
  return css`
      border-radius: ${corners.default.borderRadius};
      background-color: ${palette.light.white};
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 2.5rem 2.5rem 1.5rem;
      width: 620px;

      >h3{
        margin: 40px 0 20px;
        color: ${palette.black};
        font-size: ${typography.big.pt_18_semibold.fontSize};
        font-weight: ${typography.big.pt_18_semibold.fontWeight};
        line-height: ${typography.big.pt_18_semibold.lineHeigth};
      }
    `
})

const TopWrapper = styled.div`
        width: 100%;
        display: flex;
        justify-content: space-between;
      `

const StyledCloseIcon = styled(CloseIcon)`
  position: absolute;
  top: 2rem;
  right: 2rem;
  cursor: pointer;
`

const BottomWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

export const maxRanges = {
  car: 10,
  walk: 25,
  bike: 15,
  line: 3000,
  custom:  defaultMaxRanges.custom,
}

interface ITemplateRangeChange {
  tileId: string
  resetTileId: ()=> void
  handleSetRange: (tileId: string, type: IRange['type'], value: number, geoJSON?: {type: 'Polygon', coordinates: number[][][]},) => void
}

export const TemplateRangeChange = ({
  tileId, resetTileId, handleSetRange,
}:ITemplateRangeChange) :JSX.Element => {
  const { t } = useTranslation()
  const {
    ranges, comparedLocation,
  } = useSelector((state: RootState) => state.analysis)

  const scope = handleMaxRanges(ranges?.value, maxRanges, (
    comparedLocation?.generatedFromNow
        || comparedLocation?.alreadyGenerated))

  const [chosenRange, setChosenRange] = useState<IRange>(scope[0])

  const handleRangeChange = (range?: IRange) => {
    if (range) {
      setChosenRange(scope.find(x => x?.type === range?.type && x?.value === range?.value && range?.type !== 'custom') || range)
    }
  }

  const handleBack = () => {
    resetTileId()
  }

  const handleApply = () => {
    if (chosenRange?.type && chosenRange?.geoJSON) {
      handleSetRange(tileId, chosenRange?.type, chosenRange?.value, {
        type: chosenRange?.geoJSON?.type,
        coordinates: chosenRange?.geoJSON?.coordinates as number[][][],
      })
    } else { handleSetRange(tileId, chosenRange?.type, chosenRange?.value) }
    resetTileId()
  }

  return (
    <Wrapper>
      <TopWrapper>
        <BackButton
          handleClick={handleBack}
          isOpen
        />
        <StyledCloseIcon onClick={handleBack} />
      </TopWrapper>
      <h3>{t('placeme.templates.choose_range.modal.header')}</h3>
      <RangeTile
        chosenRange={chosenRange}
        handleChosenRangeChange={handleRangeChange}
        maxRanges={maxRanges}
        noTitle
        ranges={scope}
        tile={tileId}
      />
      <BottomWrapper>
        <TextButton
          onClick={handleBack}
          type='button'
        >
          <p>{t('generic.cancel')}</p>
        </TextButton>
        <Button
          margin='0 0 0 1.82rem'
          onClick={handleApply}
        >
          {t('generic.apply')}
        </Button>
      </BottomWrapper>
    </Wrapper>
  ) }
