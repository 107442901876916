import React from 'react'
import { DownloadAnalyseModal } from '@placeme/components/molecules/DownloadAnalyseModal/DownloadAnalyseModal'
import DownloadExcelButton, { DownloadExcelButtonProps } from './DownloadExcelButton'
import DownloadPdfButton, { DownloadPdfButtonProps } from './DownloadPdfButton'

type DownloadButtonTypes = DownloadExcelButtonProps & DownloadPdfButtonProps

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function DownloadButtons({
  hasExcelAccess = false, isTrial, downloadDisabled, reportId, isExcelLoading, setIsExcelLoading,
}: DownloadButtonTypes) {
  return (
    <>
      <DownloadAnalyseModal
        analyseId={reportId}
        trigger={(
          <DownloadPdfButton
            downloadDisabled={downloadDisabled}
            isTrial={isTrial}
          />
        )}
      />
      <DownloadExcelButton
        downloadDisabled={downloadDisabled}
        hasExcelAccess={hasExcelAccess}
        isExcelLoading={isExcelLoading}
        isTrial={isTrial}
        reportId={reportId}
        setIsExcelLoading={setIsExcelLoading}
      />
    </>
  )
}

export default DownloadButtons
