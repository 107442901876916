import React, { useContext, useState } from 'react'
import styled, { css } from 'styled-components'
import {
  CloseIcon,
  LinkWithIconChooseFromList,
} from '@dataplace.ai/ui-components/atoms'
import { Popup } from 'reactjs-popup'
import { useTranslation } from 'react-i18next'
import { ILocation } from '@dataplace.ai/types'
import { AnalyticsContext } from '@dataplace.ai/features'
import { GeosuggestForm } from '../GeosuggestForm'

const ContentWrapper = styled.div(
  () => css`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  `,
)

const Wrapper = styled.div(
  () => css`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-end;

    > button {
      margin: 0.25rem;
    }
  `,
)

const Title = styled.span(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
    margin: 0.5rem 0;
    color: ${palette.black};
    font-size: ${typography.tiny.pt_12_semibold.fontSize};
    font-weight: ${typography.tiny.pt_12_semibold.fontWeight};
    line-height: ${typography.tiny.pt_12_semibold.lineHeight};
  `
})

const Item = styled.div(({ theme }) => {
  const {
    palette, typography, corners,
  } = theme

  return css`
    display: flex;
    padding: 0.375rem 0.5rem;
    flex-direction: row;
    margin: 0.25rem 0;
    align-items: center;
    background-color: ${palette.light.main};
    border: 1px solid ${palette.light.darker};
    border-radius: ${corners.default.borderRadius};

    > span {
      flex-grow: 1;
      color: ${palette.black};
      font-size: ${typography.main.pt_15_regular.fontSize};
      font-weight: ${typography.main.pt_15_regular.fontWeight};
      line-height: ${typography.main.pt_15_regular.lineHeight};
    }

    :hover > span {
      color: ${palette.blue};
      font-size: ${typography.main.pt_15_regular.fontSize};
      font-weight: ${typography.main.pt_15_regular.fontWeight};
      line-height: ${typography.main.pt_15_regular.lineHeight};
    }
  `
})

const ListTitle = styled.span(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
    padding: 0.625rem;
    color: ${palette.black};
    font-size: ${typography.tiny.pt_12_semibold_upper.fontSize};
    font-weight: ${typography.tiny.pt_12_semibold_upper.fontWeight};
    line-height: ${typography.tiny.pt_12_semibold_upper.lineHeight};
    letter-spacing: ${typography.tiny.pt_12_semibold_upper.letterSpacing};
    text-transform: ${typography.tiny.pt_12_semibold_upper.textTransform};
  `
})
const ListItem = styled.button(({ theme }) => {
  const {
    palette, corners, typography,
  } = theme
  return css`
    text-align: start;
    padding: 0.625rem;
    color: ${palette.black};
    border-radius: ${corners.default.borderRadius};
    font-size: ${typography.main.pt_15_regular.fontSize};
    font-weight: ${typography.main.pt_15_regular.fontWeight};
    line-height: ${typography.main.pt_15_regular.lineHeight};
    border: none;
    background-color: transparent;
    cursor: pointer;

    :hover {
      background-color: ${palette.results.green.opaque};
    }
  `
})

const MenuButton = styled.div(
  () => css`
    cursor: pointer;
    display: flex;
    align-items: center;
    margin: 0.25rem 0;
  `,
)

const MenuWrapper = styled.div(({ theme }) => {
  const {
    palette, corners, shadows,
  } = theme
  return css`
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    background-color: ${palette.light.white};
    border-radius: ${corners.default.borderRadius};
    box-shadow: ${shadows.hover.boxShadow};

    > div > button {
      width: 100%;
    }
  `
})

const Error = styled.p(({ theme }) => {
  const {
    palette, typography,
  } = theme

  return css`
  margin-top: 8px;
  color: ${palette.results.red.light};
  font-size: ${typography.small.pt_13_regular.fontSize};
  font-weight: ${typography.small.pt_13_regular.fontWeight};
  line-height: ${typography.small.pt_13_regular.lineHeight};
`
})

export interface IListSelectorProps {
  title?: string
  items: ILocation[]
  placeholder?: string
  displayInput?: boolean
  maxAnnotation?: string
  onChange: React.Dispatch<React.SetStateAction<ILocation[]>>
  localizations?: ILocation[]
  localizationsLoading?: boolean
  tile?: string
  instantAdd?: boolean
  location?: { address: string; lat: number; lng: number; country: string; }
  disabled?: boolean
}

export const ListSelector = ({
  title,
  items,
  onChange,
  localizations,
  localizationsLoading,
  tile,
  displayInput = true,
  instantAdd,
  location,
  disabled,
}: IListSelectorProps): JSX.Element => {
  const { t } = useTranslation()
  const { analytics } = useContext(AnalyticsContext)
  const [error, setError] = useState('')
  const [open, setOpen] = useState(false)

  const closeModal = () => setOpen(false)

  const handleAdd = (geo: ILocation) => {
    if (geo && !items?.find(i => i.placeId === geo.placeId)) {
      if (geo?.lat?.toString() !== location?.lat?.toString()
      && geo?.lng?.toString() !== location?.lng?.toString()
      && geo?.address !== location?.address) {
        analytics?.track('Cannibalization - Address Added', {
          location: {
            addres: geo.address,
            lat: geo.lat,
            lng: geo.lng,
          },
          tile,
        })
        setError('')
        onChange([...items, geo])
      }
      else {
        setError('placeme.the_same_address_as_analysed')
      }
    }
  }

  const handleClick = (value: ILocation) => {
    analytics?.track('Cannibalization - Suggestion List Address Selected', {
      location: {
        addres: value.address,
        lat: value.lat,
        lng: value.lng,
      },
      tile,
    })
    onChange([...items, value])
  }

  const handleDelete = (selectedValue: ILocation) => {
    onChange(items.filter((item) => item.address !== selectedValue.address))
  }

  return (
    <Wrapper>
      <ContentWrapper>
        {items.map((item) => (
          <Item key={item.address}>
            <span>{item.address}</span>
            <CloseIcon onClick={() => handleDelete(item)} />
          </Item>
        ))}
        {displayInput && <Title>{title}</Title>}
        {displayInput && (
          <>
            <GeosuggestForm
              buttonText='+'
              disabled={disabled}
              // addButtonDisplayed
              handleSubmit={handleAdd}
              instantAdd={instantAdd}
            />
            {error && <Error>{t(error)}</Error>}
          </>
        )}
      </ContentWrapper>
      {localizationsLoading
        ? (
          <object
            data='assets/loaders/placeme/small.svg'
            type='image/svg+xml'
            width='30'
          >
            placeme loader
          </object>
        )
        : (
          localizations
          && (
            <Popup
              closeOnDocumentClick
              on='click'
              onClose={() => setOpen(false)}
              onOpen={() => setOpen(true)}
              open={open}
              position='top center'
              trigger={(
                <div>
                  <MenuButton>
                    <LinkWithIconChooseFromList disabled={disabled} />
                  </MenuButton>
                </div>
              )}
            >

              <MenuWrapper>
                <ListTitle>{t('placeme.list_selector.header')}</ListTitle>
                {localizations?.map(loc => (
                  <ListItem
                    key={loc?.lat + loc?.lng + loc?.address}
                    onClick={() => {
                      closeModal()
                      handleClick({
                        placeId: loc?.placeId,
                        address: loc?.address,
                        lat: loc?.lat,
                        lng: loc?.lng,
                      })
                    }}
                  >
                    {loc?.address}
                  </ListItem>
                ))}

              </MenuWrapper>

            </Popup>
          )) }
    </Wrapper>
  )
}
