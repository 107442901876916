import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'apps/placeme/src/redux/hooks'
import { RootState } from 'apps/placeme/src/redux/store'
import { IMapLocationProps, Loader } from '@dataplace.ai/ui-components/atoms'
import { saveTileData } from 'apps/placeme/src/features/Analyse/slice/analysisSlice'
import { useTranslation } from 'react-i18next'
import NewComparedLocationHeader from '@placeme/components/atoms/ComparedLocationHeader/NewComparedLocationHeader'
import { InvestmentsProps } from './@types/IInvestmentsTileData'
import { Wrapper } from './Investments.styles'
import Residential from './components/Residential'
import CommercialAndServices from './components/CommercialAndServices'
import Others from './components/Others'
import { getLayers } from './utils/functions'
import { investmentTypes } from './utils/constants'
import useOtherInvestments from './hooks/useOtherInvestments'
import MapVisualisation from './components/MapVisualisation'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function InvestmentsNew({
  data, tileId,
}: InvestmentsProps) {
  const { t } = useTranslation()
  const { value } = useSelector((state: RootState) => state.location)
  const dispatch = useAppDispatch()
  const {
    values, comparedLocation,
  } = useSelector((state: RootState) => state.analysis)
  const [mapLocation, setMapLocation] = useState<IMapLocationProps>({
    zoom: 14,
    center: {
      lat: value?.lat || 0,
      lng: value?.lng || 0,
    },
  })
  const [comparedMapLocation, setComparedMapLocation] = useState<IMapLocationProps>({
    zoom: 14,
    center: {
      lat: comparedLocation?.location?.lat || 0,
      lng: comparedLocation?.location?.lng || 0,
    },
  })

  const catchmentId = useMemo(() => (
    values?.find(c => c.id === 'surroundings')?.tiles?.find(t => t.id === tileId)?.chosenRange?.catchmentId
  ), [values])

  const mapLayers = useMemo(() => {
    const residentialLayers = getLayers({
      tileId,
      data: data?.value.investments.investments?.residential,
      sectionTilesData: values,
      iconType: investmentTypes.residential,
    })

    const commercialAndServicesLayers = getLayers({
      tileId,
      data: data?.value.investments.investments?.commercialAndServices,
      sectionTilesData: values,
      iconType: investmentTypes.commercialAndServices,
    })

    return [...residentialLayers ?? [], ...commercialAndServicesLayers ?? []]
  }, [tileId, data?.value, values, getLayers])

  const comparedMapLayers = useMemo(() => {
    const residentialLayers = getLayers({
      tileId,
      data: data?.value.comparedLocation?.investments.investments?.residential,
      sectionTilesData: values,
      iconType: investmentTypes.residential,
      compared: true,
    })

    const commercialAndServicesLayers = getLayers({
      tileId,
      data: data?.value.comparedLocation?.investments.investments?.commercialAndServices,
      sectionTilesData: values,
      iconType: investmentTypes.commercialAndServices,
      compared: true,
    })

    return [...residentialLayers ?? [], ...commercialAndServicesLayers ?? []]
  }, [tileId, data?.value.comparedLocation, values, getLayers])

  const { otherInvestmentsDataExists: shouldDisplayOthers } = useOtherInvestments({
    investments: data?.value.investments.investments?.others,
    tileId,
    sectionTilesData: values,
  })

  const { otherInvestmentsDataExists: shouldDisplayComparedOthers } = useOtherInvestments({
    investments: data?.value.comparedLocation?.investments.investments?.others,
    tileId,
    sectionTilesData: values,
  })

  const shouldDisplayNoData = useMemo(() => (
    !data?.value.investments.investments?.residential?.list.length
    && !data?.value.investments.investments?.commercialAndServices?.list.length
    && !data?.value.comparedLocation?.investments.investments?.residential?.list.length
    && !data?.value.comparedLocation?.investments.investments?.commercialAndServices?.list.length
    && !shouldDisplayOthers
    && !shouldDisplayComparedOthers
  ), [data?.value.investments.investments, shouldDisplayOthers])

  useEffect(() => {
    if (data?.value?.investments && mapLocation) {
      dispatch(saveTileData('surroundings', tileId, {
        ...data,
        mapLocation,
        comparedMapLocation: data?.value?.comparedLocation ? comparedMapLocation : undefined,
      }))
    }
  }, [mapLocation, comparedMapLocation])

  if (!data?.value || data.loading) return <Loader />
  if (shouldDisplayNoData) {
    return (
      <Wrapper>
        {t('placeme.investments.no_planned_investments')}
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      <Residential
        comparedLocationData={comparedLocation?.location}
        comparedResidential={data?.value.comparedLocation?.investments.investments?.residential}
        locationData={value}
        residential={data?.value.investments.investments?.residential}
      />
      <CommercialAndServices
        commercialAndServices={data?.value.investments.investments?.commercialAndServices}
        comparedCommercialAndServices={data.value.comparedLocation?.investments.investments?.commercialAndServices}
        comparedLocationData={comparedLocation?.location}
        locationData={value}
      />
      {(data?.value.comparedLocation?.investments.investments?.residential?.list.length
        || data?.value.comparedLocation?.investments.investments?.commercialAndServices?.list.length)
        && (data?.value.investments.investments?.residential?.list.length
          || data?.value.investments.investments?.commercialAndServices?.list.length)
        ? <NewComparedLocationHeader address={value?.address} />
        : null}
      {data?.value.investments.investments?.residential?.list.length
      || data?.value.investments.investments?.commercialAndServices?.list.length
        ? (
          <MapVisualisation
            addMapTitle='placeme.add_residential_commercial_and_services_investments_map.title'
            catchmentId={catchmentId}
            locationData={value}
            mapLayers={mapLayers}
            setMapLocation={setMapLocation}
          />
        )
        : null}
      {data?.value.comparedLocation?.investments.investments?.residential?.list.length
        || data?.value.comparedLocation?.investments.investments?.commercialAndServices?.list.length
        ? (
          <>
            <NewComparedLocationHeader address={comparedLocation?.location.address} />
            <MapVisualisation
              addMapTitle='placeme.add_residential_commercial_and_services_investments_map.title'
              catchmentId={catchmentId}
              compared
              locationData={comparedLocation?.location}
              mapLayers={comparedMapLayers}
              setMapLocation={setComparedMapLocation}
            />
          </>
        )
        : null}
      {shouldDisplayOthers || shouldDisplayComparedOthers
        ? (
          <Others
            catchmentId={catchmentId}
            comparedLocationData={comparedLocation?.location}
            comparedOtherInvestments={data?.value.comparedLocation?.investments.investments?.others}
            locationData={value}
            otherInvestments={data.value.investments.investments!.others!}
            sectionTilesData={values}
            setComparedMapLocation={setComparedMapLocation}
            setMapLocation={setMapLocation}
            tileId={tileId}
          />
        )
        : null}
    </Wrapper>
  )
}

export default InvestmentsNew
