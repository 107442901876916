import React, { useState, useRef, useEffect, useContext } from 'react'
import styled, { css } from 'styled-components'
import Geosuggest, { Suggest } from '@ubilabs/react-geosuggest'
import { Button, CloseIcon, SearchIcon, AddIcon } from '@dataplace.ai/ui-components/atoms'
import { useTranslation } from 'react-i18next'
import { ILocation } from '@dataplace.ai/types'
import { AnalyticsContext } from '@dataplace.ai/features'

const FormWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
`
const Wrapper = styled.div(({ theme }) => {
  const { palette } = theme
  return css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    height: 2.5rem;
    width: 100%;
    margin-right: 20px;

    position: relative;
    transition-duration: .2s;

    box-sizing: border-box;
    border: 1px solid ${palette.dark.lightest};
    border-radius: 7px;
    color: #b5b9bf;
    font-size: 14px;
    line-height: inherit;
    transition: .2s linear;
    box-shadow: none;
    -webkit-box-shadow: none;
    background-color: white;
`
})

const IconsWrapper = styled.div(({ theme }) => {
  const {
    palette, shadows,
  } = theme
  return css`
    display: flex;
    > svg {
      display: block;
      cursor: pointer;
      margin-right: 5px;
    }

    :focus-within > .search {
      display: none;
    }

    :focus-within {
      outline: none;
      box-shadow: ${shadows.hover.boxShadow};
      border: 2px solid ${palette.blue};
      color: ${palette.blue};
    }
  `
})

const StyledGeosuggest = styled(Geosuggest)(({ theme }) => {
  const { palette } = theme
  return css`
    padding: 0;
    height: 30px;
    width: 92%;
    margin-left: 2px;
    border: none;
    transition-duration: .2s;

    .geosuggest__suggests--hidden {
      max-height: 0;
      overflow: hidden;
      border-width: 0;
    }

    ul.geosuggest__suggests {
      margin-top: 0.75rem;
      border-radius: 7px;
      background-color: white;
      box-shadow: 0 2px 8px -2px rgba(0,0,0,.5);
      position: relative;
      z-index: 120;
      list-style-type: none;
      li:first-child {
        padding: 8px 10px 4px;
      }

      li {
        padding: 4px 10px;
        cursor: pointer;
        color: ${palette.blue};
      }
    }

  input[type=text].geosuggest__input {
      box-sizing: border-box;
      border: none;
      color: ${palette.blue};
      padding: 0 12px;
      font-size: 14px;
      line-height: inherit;
      transition: .2s linear;
      box-shadow: none;
      -webkit-box-shadow: none;
      width: 100%;
      position: relative;
      top: 4px;
      :focus{
        outline: none;
        color: ${palette.blue};
      }
  }
`
})

export interface IGeosuggestFormProps {
  buttonText: string;
  handleSubmit: (location: ILocation) => void,
  handleSelect?: (location: ILocation) => void,
  submitButtonDisplayed?: boolean,
  addButtonDisplayed?: boolean,
  instantAdd?: boolean
  disabled?: boolean
  loading?: boolean
}

export const GeosuggestForm = ({
  buttonText,
  handleSubmit,
  submitButtonDisplayed,
  addButtonDisplayed,
  handleSelect,
  instantAdd,
  disabled,
  loading,
}: IGeosuggestFormProps): JSX.Element => {
  const geosuggestRef = useRef<Geosuggest>(null)
  const [chosenLocation, setChosenLocation] = useState<ILocation>()
  const { t } = useTranslation()

  useEffect(() => {
    if (chosenLocation && handleSelect) handleSelect(chosenLocation)
  }, [chosenLocation])
  const { analytics } = useContext(AnalyticsContext)

  const onSuggestSelect = async (suggest?: Suggest) => {
    if (suggest && suggest.location) {
      setChosenLocation({
        placeId: suggest.placeId,
        address: suggest.label,
        lat: suggest.location.lat,
        lng: suggest.location.lng,
      })
    }
  }

  const handleSave = () => {
    if (chosenLocation) {
      analytics?.track('Analyse Button Clicked', {
        location: {
          ...chosenLocation,
        },
      })
      handleSubmit(chosenLocation)
    }
    if (geosuggestRef.current) {
      setChosenLocation(undefined)
      geosuggestRef.current.clear()
      geosuggestRef.current.blur()
    }
  }

  const handleDelete = () => {
    if (geosuggestRef.current) {
      setChosenLocation(undefined)
      geosuggestRef.current.clear()
      geosuggestRef.current.blur()
    }
  }

  // hooks

  // for instant adding addres after choose in geosuggest, without + button
  useEffect(() => {
    if (instantAdd && chosenLocation && geosuggestRef.current) {
      handleSubmit(chosenLocation)
      setChosenLocation(undefined)
      geosuggestRef.current.clear()
      geosuggestRef.current.blur()
    }
  }, [chosenLocation])

  return (
    <FormWrapper>
      <Wrapper
        tabIndex={0}
      >
        <StyledGeosuggest
          ref={geosuggestRef}
          autoComplete='off'
          disabled={disabled}
          initialValue=''
          onSuggestSelect={onSuggestSelect}
          placeholder={t('generic.type_address')}
        />
        <IconsWrapper>
          {!!chosenLocation?.address && (
            <CloseIcon
              onClick={handleDelete}
              tabIndex={0}
            />
          )}
          {addButtonDisplayed && !!chosenLocation?.address && (
            <AddIcon
              onClick={handleSave}
              tabIndex={0}
            />
          )}
          {!chosenLocation?.address && (
            <SearchIcon
              className='search'
              tabIndex={0}
            />
          )}
        </IconsWrapper>
      </Wrapper>
      {submitButtonDisplayed && (
        <Button
          loading={loading}
          onClick={handleSave}
        >
          {buttonText}
        </Button>
      )}
    </FormWrapper>
  )
}
