import styled, { css } from 'styled-components'

export const Wrapper = styled.div<{ background: 'light' | 'dark' }>(({
  theme: { palette },
  background,
}) => css`
    display: flex;
    flex-direction: column;
    padding: 1.25rem 1.5rem;
    background-color: ${background === 'light' ? palette.light.white : palette.light.main};
`)

export const TypeRangeWrapper = styled.div(({
  theme: {
    palette,
    corners,
  },
}) => css`
    display: flex;
    flex-direction: column;
    background: ${palette.light.white};
    border: 1.5px dashed ${palette.light.darkest};
    border-radius: ${corners.default.borderRadius};
    padding: 1rem;
    color: ${palette.black};
    margin-top: 1.5rem;
`)

interface ParametersWrapperProps {
  $grid?: number
}

export const ParametersWrapper = styled.div<ParametersWrapperProps>(({ $grid }) => css`
  display: grid;
  grid-template-columns: ${$grid ? `repeat(${$grid}, 1fr)` : 'repeat(3, 1fr)'};
  gap: 10px;
`)

export const DividerBox = styled.div`
  height: 3rem;
`
export const AdditionalParamsWrapper = styled.div`
  margin-top: 2rem;
`
