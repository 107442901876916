/* eslint-disable max-lines */
import { CloseIcon, CoinsButton, LinkWithIconDelete, LinkWithIconEdit, RadioButton, TextButton, Tooltip, Range } from '@dataplace.ai/ui-components/atoms'
import { defaultTheme } from '@dataplace.ai/ui-components/themes/defaultTheme'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Popup } from 'reactjs-popup'
import styled, { css } from 'styled-components'
import { useSelector } from 'react-redux'
import { RootState } from 'apps/placeme/src/redux/store'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { IRange, RangeType } from '@dataplace.ai/types'
import { useAppDispatch } from 'apps/placeme/src/redux/hooks'
import { AuthContext } from '@dataplace.ai/features'
import { emails } from '@dataplace.ai/constants'
import { getProperPlanName } from '@dataplace.ai/functions/utils'
import { ReactComponent as LockIcon } from '../../../../../../../../libs/shared/assets/src/lib/icons/lock.svg'
import { ReactComponent as Stats } from '../../../../../../../../libs/shared/assets/src/lib/icons/placeme/stats.svg'
import { ReactComponent as HelpCircle } from '../../../../../../../../libs/shared/assets/src/lib/icons/dataplace/help-circle.svg'
import { ReactComponent as Warning } from '../../../../../../../../libs/shared/assets/src/lib/icons/warrningIcon.svg'
import { creditsNumber, tilesWithDependentCredits } from '..'
import { tilesWithoutRangeChoosing } from '../Tile/constant/TilesWithDoublePropertiesChoose'
import { addTileAction, catchmentAndDataAction, saveTemplateState } from '../../../slice/analysisSlice'
import { ITilesWithSameRangeAndType, NotEnoughCreditsForTemplate, SameRangeForSameTile, TemplateRangeChange } from './components'
import { ICombinedSectionTile } from '../../../slice/@types/ISectionTile'
import { ITile } from '../../../slice/@types/ITile'
import { ITemplateState } from '../../../slice/@types/ITemplateState'
import { ITemplatesData } from '../../organisms/AnalyseTemplates/templatesData'

const Wrapper = styled.div(({ theme }) => {
  const {
    palette, corners,
  } = theme
  return css`
      border-radius: ${corners.default.borderRadius};
      background-color: ${palette.light.white};
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 2.5rem 2.5rem 1.5rem;
      width: 580px;
      max-height: 90vh;
    `
})

const Trigger = styled.div<{isLongList?: boolean}>(({ theme }) => {
  const { palette } = theme
  return css`
      display: flex;
      flex-direction: column;
      border-radius: 6px;
      border: 1px solid ${palette.light.darker};
      cursor: pointer;
  `
})

const StyledCloseIcon = styled(CloseIcon)`
  position: absolute;
  top: 2rem;
  right: 2rem;
  cursor: pointer;
`

const TopWrapper = styled.div(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-bottom: 1.25rem;
  
      h3 {
        display: flex;
        align-items: center;
        color: ${palette.black};
        font-size: ${typography.big.pt_28_semibold.fontSize};
        font-weight: ${typography.big.pt_28_semibold.fontWeight};
        line-height: ${typography.big.pt_28_semibold.lineHeight};

        >svg{
            margin-right: .5rem;
            height: 2rem;
            width: 2rem;
        }
      }
    `
})

const DescHeader = styled.h4(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
        margin: 1rem 0 0;
        color: ${palette.black};
        font-weight: ${typography.main.pt_15_semibold.fontWeight};
        font-size: ${typography.main.pt_15_semibold.fontSize};
        line-height: ${typography.main.pt_15_semibold.lineHeight};
        letter-spacing: ${typography.main.pt_15_semibold_upper.letterSpacing};
        text-transform: ${typography.main.pt_15_semibold_upper.textTransform};
    `
})

const DescPara = styled.p(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
        margin: 10px 0 20px;
        color: ${palette.black};
        font-size: ${typography.tiny.pt_12_regular.fontSize};
        font-weight: ${typography.tiny.pt_12_regular.fontWeight};
        line-height: ${typography.tiny.pt_12_regular.lineHeight};
    `
})

const List = styled.ul(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
        color: ${palette.dark.normal};
        margin-top: .5rem;
        margin-left: 20px;

        >li{
            width:100%;
            margin-bottom: .5rem;
            font-size: ${typography.main.pt_15_regular.fontSize};
            font-weight: ${typography.main.pt_15_regular.fontWeight};
            line-height: ${typography.main.pt_15_regular.lineHeight};

            >div{
                display: flex;
                align-items: center;
                justify-content: space-between;
                width:100%;
                >div{
                    display: flex;
                    align-items: center;
                    >div{
                      display: flex;
                      align-items: center;
                        >svg {
                            width: 15px;
                            height: 15px;
                            margin-left: .5rem;
                            path {
                                fill: ${palette.blue};
                            }
                        }

                        .outside-plan-name{
                          color: #484E5E;
                          font-style: italic;
                          font-weight: 500;
                          font-size: 13px;
                        }
                        .outside-plan-lock{
                          margin-left: .5rem;
                          width: 10px;
                          height: 10px;
                          path {
                            fill: none;
                          }
                        }
                    }
                    >button{
                            margin-left: .5rem;
                    }

                    
                }
            }
        }
        ` })

const AnimatedCoins = styled.div(({ theme }) => {
  const { palette } = theme
  return css`
  
      margin-left: 10px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 3px 7px;
      border-radius: 1rem;
      background: #C6EAE1;
      color: ${palette.blue};
      min-width: 38px;
  
      svg {
          margin-right: 7px;
          path {
              fill: ${palette.blue};
          }
      }
  
      >p{
          margin-right: 4px;
      }
  
      .help-circle{
          margin: 0 0 -1px;
          width: 15px;
          height: 15px;
      }

      .stats{
          margin-left: 0;
      }
      `
})

const RangeWrapper = styled.div(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
          display: flex;
          flex-direction: column;
          padding: 20px;
          margin: 2rem 0;
          background: ${palette.light.main};
          border: 1px dashed ${palette.light.darker};
          border-radius: 6px;
  
          >h5{
              margin: 0 0 .5rem;
              color: ${palette.black};
              font-weight: ${typography.main.pt_15_semibold.fontWeight};
              font-size: ${typography.main.pt_15_semibold.fontSize};
              line-height: ${typography.main.pt_15_semibold.lineHeight};
              letter-spacing: ${typography.main.pt_15_semibold_upper.letterSpacing};
              text-transform: ${typography.main.pt_15_semibold_upper.textTransform};
          }
          >p{
              margin: 0 0 .5rem;
              color: ${palette.black};
              font-size: ${typography.tiny.pt_12_regular.fontSize};
              font-weight: ${typography.tiny.pt_12_regular.fontWeight};
              line-height: ${typography.tiny.pt_12_regular.lineHeight};
          }
      `
})

const OutsidePlanTiles = styled.div(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
    width: 100%;
    padding-top: 1.5rem;
    border-top: 1px solid #EDF0F5;

    >span{
      color:${palette.black};
      font-size: ${typography.small.pt_13_semibold.fontSize};
      font-weight: ${typography.small.pt_13_semibold.fontWeight};
      line-height: ${typography.small.pt_13_semibold.lineHeight};
      margin-bottom: 1rem;

      >a{
        color: ${palette.blue};
        font-size: ${typography.tiny.pt_12_semibold.fontSize};
        font-weight: ${typography.tiny.pt_12_semibold.fontWeight};
        line-height: ${typography.tiny.pt_12_semibold.lineHeight};
        margin-left: 8px;
        cursor: pointer;
      }
    }
  `
})

const RadioButtonWrapper = styled.div(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
    margin-top: .5rem;
    color:${palette.black};
    display: flex;
    align-items: center;
    font-size: ${typography.main.pt_15_regular.fontSize};
    font-weight: ${typography.main.pt_15_regular.fontWeight};
    line-height: ${typography.main.pt_15_regular.lineHeight};
  
    @media (max-width:1350px){
      font-size: ${typography.small.pt_13_regular.fontSize};
      font-weight: ${typography.small.pt_13_regular.fontWeight};
      line-height: ${typography.small.pt_13_regular.lineHeight};
      margin-right: 15px;
    }
  `
})

const SettingsRangeWrapper = styled.div<{individual?: boolean}>(({
  theme, individual,
}) => {
  const {
    palette, typography,
  } = theme
  return css`
        width: ${individual ? '90%' : '70%'};
        display: flex;
        flex-direction: column;
        padding: 10px;
        margin: 1rem 0 1rem 2rem;
        background-color: ${palette.light.white};
        border: 1px solid ${palette.light.darker};
        border-radius: 6px;
        color: ${palette.black};

        .top-wrapper{
            display: flex;
            align-items: center;
            >span{
                >svg{
                    width: 1rem;
                    height: 1rem;
                }
            }
            >button{
                margin-left: .5rem;
            }
            >ul{
                color: ${palette.dark.normal};
                margin-left: 20px;
                width: 100%;

                >li{
                    width:100%;
                    margin-bottom: .5rem;
                    font-size: ${typography.tiny.pt_12_regular.fontSize};
                    font-weight: ${typography.tiny.pt_12_regular.fontWeight};
                    line-height: ${typography.tiny.pt_12_regular.lineHeight};

                    >div{
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        width:100%;
                        >div{
                            display: flex;
                            align-items: center;

                            ${individual && css`
                            >span{
                              >svg {
                                    width: 15px;
                                    height: 15px;
                                }
                            }
                            `}
                            >div{
                                >svg {
                                    width: 15px;
                                    height: 15px;
                                    margin-left: .5rem;
                                    path {
                                        fill: ${palette.blue};
                                    }
                                }
                            }
                            >button{
                                    margin-left: .5rem;
                                }
                        }
                    }
                 }
            }
        }

        .bottom-wrapper{
            display: flex;
            align-items: flex-start;
            margin-top: 1.5rem;
            >p{
              font-size: ${typography.tiny.pt_12_regular.fontSize};
              font-weight: ${typography.tiny.pt_12_regular.fontWeight};
              line-height: ${typography.tiny.pt_12_regular.lineHeight};
            }
            >svg{
              margin-right: .5rem;
            }
        }
    `
})

const AdditionalSettingsInfo = styled.p(({ theme }) => {
  const {
    palette, typography,
  } = theme

  return css`
      margin: 1rem 0;  
      width: 70%;
      color: ${palette.results.red.dark};
      font-size: ${typography.tiny.pt_12_regular.fontSize};
      font-weight: ${typography.tiny.pt_12_regular.fontWeight};
      line-height: ${typography.tiny.pt_12_regular.lineHeight};
  `
})

const BottomWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const ScrollWrapper = styled.div`
  margin-right: .5rem;
  max-height: 550px;
`

const tilesWithNoRange = [...tilesWithoutRangeChoosing, 'transport', 'satellite', 'access_range', 'overlapping_ranges', 'cannibalization', 'customer_origin', 'potential_model', 'mcd_competition_traffic', 'mcd_potential_model', 'demo_sales_prediction_model']
export const tilesWithDoubleProperties = ['traffic_visualisation', 'customer_origin', 'traffic_visualisation_map', 'cannibalization', 'transport', 'potential_model', 'gravity_model', 'access_range', 'overlapping_ranges', 'local_market_share', 'traffic_visualisation_big_ben', 'old_traffic_visualisation_big_ben', 'mcd_competition_traffic', 'mcd_potential_model', 'demo_sales_prediction_model']
export interface ITemplateTileModal {
  trigger: JSX.Element
  title: string
  icon: JSX.Element
  style?: React.CSSProperties | undefined
  templateTiles: ITemplatesData['tiles']
  setTilesNextToLoad: React.Dispatch<React.SetStateAction<{
    loading: boolean
    error: string
    tile: string
    chosenRange: {
      type: RangeType
      value: number
    }
  }[] | undefined>>
}

export const TemplateTileModal = ({
  trigger, title, icon, style, templateTiles, setTilesNextToLoad,
}:ITemplateTileModal) :JSX.Element => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  // we need ref to get current state in event listener
  const authContext = useContext(AuthContext)
  const [token, setToken] = useState('')

  const {
    tiles, templateState, creditsAmount,
  } = useSelector((state: RootState) => state.analysis)
  const { currentSubscriptionData } = useSelector((state: RootState) => state.location)
  const [open, setOpen] = useState(false)
  const [readyToContinue, setReadyToContinue] = useState(false)
  const [chosenRangeOption, setChosenRangeOption] = useState('same_for_all')
  const [tileIdToBeRangeChange, setTileIdToBeRangeChange] = useState<string | undefined>()
  const [sameRangeForAll, setSameRangeForAll] = useState<{
    type: IRange['type'], value: number, geoJSON?: {type: string, coordinates: number[][][]}}>({
    type: 'walk',
    value: 5,
  })
  const [chosenTiles, setChosenTiles] = useState(templateTiles?.filter(item => item?.isInPlan))
  const [tilesWithSameRangeAndType, setTilesWithSameRangeAndType] = useState<ITilesWithSameRangeAndType[]>([])

  // functions
  const openModal = () => {
    setOpen(true)
  }
  const closeModal = () => {
    setOpen(false)
  }

  const getMissingCredits = () => {
    if (creditsAmount) {
      if (chosenTiles?.filter(tile => !tilesWithDependentCredits.includes(tile.id))?.length > creditsAmount) {
        return Math.abs(creditsAmount - chosenTiles?.filter(tile =>
          !tilesWithDependentCredits.includes(tile.id))?.length)
      }
    }
    return 0
  }

  const getTileHint = (tileId: string) =>
    tiles?.find(cat => !!cat?.tiles?.find(tile => tile?.id === tileId))?.tiles?.find(tile =>
      tile?.id === tileId)?.labelHint

  const getWhiteTiles = () : string[] => {
    const whiteTiles: string[] = []
    tiles?.forEach(cat => cat?.tiles?.forEach(tile => (tile?.plan?.id === 'white'
      ? whiteTiles.push(tile?.id)
      : null)))
    return whiteTiles
  }

  const getCategoryId = (tileId: string) =>
    tiles?.find(cat => !!cat?.tiles?.find(tile => tile?.id === tileId))?.id

  const handleDeleteTile = (tileId: string) => {
    setChosenTiles(chosenTiles?.filter(tile => tile?.id !== tileId))
  }

  const handleCancel = () => {
    setChosenTiles(templateTiles?.filter(item => item?.isInPlan))
    closeModal()
  }

  const handleChangeRangeOpen = (tileId: string) => {
    setTileIdToBeRangeChange(tileId)
  }

  const handleSetRange = (
    tileId: string, type: IRange['type'], value: number, geoJSON?: {type: 'Polygon', coordinates: number[][][]},
  ) => {
    if (tileId === 'all') {
      setSameRangeForAll({
        type,
        value,
        geoJSON,
      })
    } else {
      const updatedChosenTiles = chosenTiles?.map(tile => (tile?.id === tileId
        ? {
          ...tile,
          id: tile?.id,
          suggestedRange: {
            type,
            value,
            geoJSON,
          },
        }
        : tile))
      setChosenTiles(updatedChosenTiles)
    }
  }

  const handleApply = () => {
    // range the same for all tiles
    if (chosenRangeOption === 'same_for_all') {
      dispatch(saveTemplateState(chosenTiles?.map(tile => ({
        // loading false for tiles with double properties choose
        loading: false,
        error: '',
        tile: tile?.id,
        hasAdditionalSettings: tilesWithDoubleProperties.includes(tile?.id.split('-')?.[0]),
        chosenRange:{
          type: tilesWithNoRange.includes(tile?.id?.split('-')?.[0]) ? 'line' : sameRangeForAll?.type,
          value: tilesWithNoRange.includes(tile?.id?.split('-')?.[0]) ? 250 : sameRangeForAll?.value,
          geoJSON: tilesWithNoRange.includes(tile?.id?.split('-')?.[0]) ? undefined : sameRangeForAll?.geoJSON,
        },
      }))))
    } else {
      // range unique for each range
      dispatch(saveTemplateState(chosenTiles?.map(tile => ({
        // loading false for tiles with double properties choose
        loading:  false,
        error: '',
        tile: tile?.id,
        hasAdditionalSettings: tilesWithDoubleProperties.includes(tile?.id.split('-')?.[0]),
        chosenRange:{
          type: tile?.suggestedRange?.type
            || (tilesWithNoRange.includes(tile?.id?.split('-')?.[0]) ? 'line' : 'walk'),
          value: tile?.suggestedRange?.value
            || (tilesWithNoRange.includes(tile?.id?.split('-')?.[0]) ? 250 : 5),
          geoJSON: tile?.suggestedRange?.geoJSON,
        },
      }))))
    }
    setReadyToContinue(true)
  }

  const handleGetData = () => {
    // tiles WITH additional settings
    const tilesWithAdditionalSettings = templateState?.filter(item => tilesWithDoubleProperties.includes(item?.tile.split('-')?.[0]))
    // tiles WITHOUT additional settings
    const tilesWithoutAdditionalSettings = templateState?.filter(item => !tilesWithDoubleProperties.includes(item?.tile.split('-')?.[0]))
    // for tiles WITH additional settings: set catchment -> add tile
    tilesWithAdditionalSettings?.forEach(item => {
      const sectionTile = tiles?.find(cat => cat.id === getCategoryId(item.tile.split('-')?.[0]) || '') as ICombinedSectionTile
      const tile = sectionTile?.tiles?.find(t => t.id === item.tile.split('-')?.[0]) as ITile
      dispatch(addTileAction(token, sectionTile, {
        ...tile,
        chosenRange: !tilesWithNoRange?.includes(item.tile.split('-')?.[0])
          ? {
            value: item?.chosenRange?.value,
            type: item?.chosenRange?.type,
            id: `${item?.chosenRange?.type}-${item?.chosenRange?.value}`,
          }
          : undefined,
      }))
    })
    // settings and calculations for synchronous tiles loading
    let tilesToLoad = tilesWithoutAdditionalSettings
    if (tilesWithoutAdditionalSettings?.length > 4) {
      tilesToLoad = tilesWithoutAdditionalSettings?.slice(0, 4)
      setTilesNextToLoad(tilesWithoutAdditionalSettings?.slice(4, tilesWithoutAdditionalSettings?.length))
    }
    const newTemplateState: ITemplateState[] = []

    // for tiles WITHOUT additional settings: get catchment -> data -> add tile
    templateState?.forEach(tile => (tilesToLoad?.find(item => item.tile === tile.tile)
      ? newTemplateState.push({
        ...tile,
        loading: true,
      })
      : newTemplateState.push(tile)))

    tilesToLoad?.forEach(tile => {
      dispatch(catchmentAndDataAction(
        token,
        {
          ...tile?.chosenRange,
          id: `${tile?.chosenRange?.type}-${tile?.chosenRange?.value}`,
        },
        getCategoryId(tile.tile.split('-')?.[0]) || '',
        tile.tile,
        currentSubscriptionData?.value?.subscriptionId || '',
      ))
    })

    closeModal()
    dispatch(saveTemplateState(newTemplateState))
  }

  // hooks
  useEffect(() => {
    authContext.userData?.user?.getIdToken()?.then(response => {
      setToken(response)
    })
  }, [authContext])

  // check and set tiles with the same type and range
  useEffect(() => {
    if (chosenTiles?.length) {
      const sameRangeAndTypeTiles : ITilesWithSameRangeAndType[] = []
      chosenTiles?.forEach(tile => {
        if (!sameRangeAndTypeTiles.find(item => item?.label === `placeme.tile.${tile?.id?.split('-')?.[0]}`)) {
          const sameRangeTiles = chosenTiles?.filter(t => t.id.split('-')?.[0] === tile.id.split('-')?.[0]
       && ((t.suggestedRange?.type === tile.suggestedRange?.type
       && t.suggestedRange?.value === tile.suggestedRange?.value)
       || chosenRangeOption === 'same_for_all'))
          if (sameRangeTiles?.length > 1) {
            sameRangeAndTypeTiles.push({
              label: `placeme.tile.${sameRangeTiles?.[0]?.id?.split('-')?.[0]}`,
              amount: sameRangeTiles?.length,
            })
          }
        }
      })
      setTilesWithSameRangeAndType(sameRangeAndTypeTiles)
    }
  }, [chosenTiles, chosenRangeOption])

  // fires get data for tiles in template
  useEffect(() => {
    if (readyToContinue) {
      handleGetData()
    }
  }, [readyToContinue])

  return (
    <Popup
      closeOnDocumentClick={false}
      modal
      onClose={() => {
        setOpen(false)
      }}
      onOpen={openModal}
      open={open}
      overlayStyle={defaultTheme.overlay}
      trigger={<Trigger style={style}>{trigger}</Trigger>}
    >
      <>
        {/* if tileIdToBeRangeChange is undefined we display basic modal view -> when it has some value
      (value is set when change button click - sets tile id or all for same_for_all) it shows range selection part
      (TemplateRangeChange) */}
        { !tileIdToBeRangeChange
          ? (
            <Wrapper>
              <TopWrapper>
                <h3>
                  {icon}
                  {t(title)}
                </h3>
                <StyledCloseIcon onClick={handleCancel} />
              </TopWrapper>
              <PerfectScrollbar
                className='scroll'
              >
                <ScrollWrapper>
                  <DescHeader>{t('placeme.template.modal.desc_header')}</DescHeader>
                  <DescPara>{t('placeme.template.modal.desc_para')}</DescPara>
                  <List>
                    {chosenTiles?.map(tile => (
                      <li key={tile?.id}>
                        <div>
                          <div>
                            {t(`placeme.tile.${tile?.id?.split('-')?.[0]}`)}
                            <Tooltip
                              content={t(getTileHint(tile?.id?.split('-')?.[0]) || '')}
                              maxWidth='300px'
                              position='right center'
                            >
                              <HelpCircle className='help-circle' />
                            </Tooltip>
                          </div>
                          <div>
                            <AnimatedCoins>
                              {tilesWithDependentCredits?.includes(tile?.id?.split('-')?.[0])
                                ? (
                                  <>
                                    <Stats className='stats' />
                                    <Tooltip
                                      content={t('placeme.templates.tiles_depended_credits.tooltip_2')}
                                      maxWidth='300px'
                                      position='left center'
                                    >
                                      <HelpCircle className='help-circle' />
                                    </Tooltip>
                                  </>
                                )
                                : (
                                  <>
                                    <Stats className='stats' />
                                    <p>1</p>
                                  </>
                                )}
                            </AnimatedCoins>
                            <LinkWithIconDelete
                              focusStyle={false}
                              hideLabel
                              onClick={() => handleDeleteTile(tile?.id)}
                            />
                          </div>
                        </div>
                      </li>
                    ))}
                  </List>
                  {/* tiles outside plan */}
                  {!!templateTiles?.filter(item => !item?.isInPlan)?.length
                  && (
                    <OutsidePlanTiles>
                      <span>
                        {t('placeme.templates.outside_plan_tiles.header')}
                        <a href={`mailto:${emails.sales_pl}`}>{t('generic.contact_us')}</a>
                      </span>
                      <List>
                        {templateTiles?.filter(item => !item?.isInPlan)?.map(tile => (
                          <li key={tile?.id}>
                            <div>
                              <div>
                                {t(`placeme.tile.${tile?.id?.split('-')?.[0]}`)}
                                <Tooltip
                                  content={t(getTileHint(tile?.id?.split('-')?.[0]) || '')}
                                  maxWidth='300px'
                                  position='right center'
                                >
                                  <HelpCircle className='help-circle' />
                                </Tooltip>
                              </div>
                              <div>
                                <Tooltip
                                  content={t('placeme.templates.tile_outside_plan.tooltip')}
                                  maxWidth='300px'
                                  position='left center'
                                >
                                  <>
                                    {tile?.plan?.id && <p className='outside-plan-name'>{t(`placeme.generic.subscription.${getProperPlanName(tile?.plan?.id)}`)}</p>}
                                    <LockIcon className='outside-plan-lock' />
                                  </>
                                </Tooltip>
                              </div>
                            </div>
                          </li>
                        ))}
                      </List>
                    </OutsidePlanTiles>
                  )}
                  {/* range settings - for all */}
                  <RangeWrapper>
                    <h5>{t('generic.range')}</h5>
                    <p>{t('placeme.templates.choose_range.header')}</p>
                    <RadioButtonWrapper
                      onClick={() => {
                        setChosenRangeOption('same_for_all')
                      }}
                    >
                      <RadioButton
                        checked={chosenRangeOption === 'same_for_all'}
                        onChange={() => {
                          setChosenRangeOption('same_for_all')
                        }}
                      />
                      {t('placeme.templates.range.options.same_for_all')}
                    </RadioButtonWrapper>
                    {chosenRangeOption === 'same_for_all'
                && (
                  <SettingsRangeWrapper>
                    <div className='top-wrapper'>
                      <Range
                        range={{
                          id: `${sameRangeForAll?.value}_${sameRangeForAll?.type}`,
                          value: sameRangeForAll?.value,
                          type: sameRangeForAll?.type,
                        }}
                      />
                      <LinkWithIconEdit
                        focusStyle={false}
                        onClick={() => handleChangeRangeOpen('all')}
                      />
                    </div>
                    <div className='bottom-wrapper'>
                      <Warning />
                      <p>{t('placeme.templates.ranges.additional_when_change')}</p>
                    </div>
                  </SettingsRangeWrapper>
                )}
                    {/* range settings - unique for each */}
                    <RadioButtonWrapper
                      onClick={() => {
                        setChosenRangeOption('unique')
                      }}
                    >
                      <RadioButton
                        checked={chosenRangeOption === 'unique'}
                        onChange={() => {
                          setChosenRangeOption('unique')
                        }}
                      />
                      {t('placeme.templates.range.options.unique')}
                    </RadioButtonWrapper>
                    {chosenRangeOption === 'unique'
                && (
                  <SettingsRangeWrapper individual>
                    <div className='top-wrapper'>
                      <ul>
                        {chosenTiles?.map(tile => (
                          <li key={tile.id}>
                            <div>
                              <div>
                                {t(`placeme.tile.${tile?.id?.split('-')?.[0]}`)}
                              </div>
                              { tilesWithNoRange?.includes(tile?.id?.split('-')?.[0])
                                ? (
                                  <div>
                                    {t('placeme.templates.no_range_choosing_for_this_tile')}
                                  </div>
                                )
                                : (
                                  <div>
                                    <Range
                                      range={{
                                        id: `${tile?.suggestedRange?.value || 5}_${tile?.suggestedRange?.type || 'walk'}`,
                                        value: tile?.suggestedRange?.value || 5,
                                        type: tile?.suggestedRange?.type || 'walk',
                                      }}
                                      withoutTextType
                                    />
                                    <LinkWithIconEdit
                                      focusStyle={false}
                                      onClick={() => handleChangeRangeOpen(tile?.id)}
                                    />
                                  </div>
                                ) }
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div className='bottom-wrapper'>
                      <Warning />
                      <p>{t('placeme.templates.ranges.additional_when_change')}</p>
                    </div>
                  </SettingsRangeWrapper>
                )}
                  </RangeWrapper>
                </ScrollWrapper>
              </PerfectScrollbar>
              {!!chosenTiles?.filter(tile => tilesWithDoubleProperties.includes(tile?.id?.split('-')?.[0]))?.length
              && <AdditionalSettingsInfo>{t('placeme.templates.tiles_with_additional_settings')}</AdditionalSettingsInfo>}
              <BottomWrapper>
                <TextButton
                  onClick={handleCancel}
                  type='button'
                >
                  <p>{t('generic.cancel')}</p>
                </TextButton>
                {tilesWithSameRangeAndType?.length
                  ? (
                    <SameRangeForSameTile
                      tilesWithSameRangeAndType={tilesWithSameRangeAndType}
                      trigger={(
                        <CoinsButton
                          margin='0 0 0 1.82rem'
                          text={t('generic.apply')}
                          value={creditsNumber(chosenTiles?.map(tile => tile?.id?.split('-')?.[0]), getWhiteTiles())}
                        />
                      )}
                    />
                  )
                  : (getMissingCredits()
                    ? (
                      <NotEnoughCreditsForTemplate
                        missingCredits={getMissingCredits()}
                        trigger={(
                          <CoinsButton
                            margin='0 0 0 1.82rem'
                            text={t('generic.apply')}
                            value={creditsNumber(chosenTiles?.map(tile => tile?.id?.split('-')?.[0]), getWhiteTiles())}
                          />
                        )}
                      />
                    )
                    : (
                      <CoinsButton
                        margin='0 0 0 1.82rem'
                        onClick={handleApply}
                        text={t('generic.apply')}
                        value={creditsNumber(chosenTiles?.map(tile => tile?.id?.split('-')?.[0]), getWhiteTiles())}
                      />
                    )
                  )}
              </BottomWrapper>
            </Wrapper>
          )
          : (
            <TemplateRangeChange
              handleSetRange={handleSetRange}
              resetTileId={() => setTileIdToBeRangeChange(undefined)}
              tileId={tileIdToBeRangeChange}
            />
          )}
      </>
    </Popup>
  )
}
