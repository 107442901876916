/* eslint-disable max-lines */
import React from 'react'
import { ReactComponent as InhabitantsVisitorsIcon } from '@dataplace.ai/assets/lib/icons/placeme/population.svg'
import { ReactComponent as MarketIcon } from '@dataplace.ai/assets/lib/icons/placeme/market.svg'
import { ReactComponent as TransportIcon } from '@dataplace.ai/assets/lib/icons/placeme/transport.svg'
import { ReactComponent as PotentialIcon } from '@dataplace.ai/assets/lib/icons/placeme/potential.svg'
import { ReactComponent as SurroundingsIcon } from '@dataplace.ai/assets/lib/icons/placeme/surroundings.svg'
import { ReactComponent as TrafficIcon } from '@dataplace.ai/assets/lib/icons/placeme/traffic.svg'
import { ReactComponent as VisualizationIcon } from '@dataplace.ai/assets/lib/icons/placeme/visualization.svg'
import { ReactComponent as PaymentsIcon } from '@dataplace.ai/assets/lib/icons/placeme/payments.svg'
import { ReactComponent as BigBenIcon } from '@dataplace.ai/assets/lib/icons/placeme/duzyben.svg'
import { ReactComponent as McdIcon } from '@dataplace.ai/assets/lib/icons/placeme/mcd.svg'
import { ReactComponent as DolSapIcon } from '@dataplace.ai/assets/lib/icons/placeme/dolci_sapori_logo.svg'
import { ReactComponent as AnwIcon } from '@dataplace.ai/assets/lib/icons/placeme/moya.svg'
import { PotentialWithinChain } from '@placeme/components/organisms/Tiles/Potential/PotentialWithinChain/PotentialWithinChain'
import { AnwPotentialModel } from '@placeme/components/organisms/Tiles/Anw/AnwPotentialModel/AnwPotentialModel/AnwPotentialModel'
import { DemoScoringPrediction } from '@placeme/components/organisms/Tiles/Anw/AnwPotentialModel/DemoScoringPrediction/DemoScoringPrediction'
import { DemoSalesPredictionModel } from '@placeme/components/organisms/Tiles/Mcd/McdPotentialModel/DemoSalesPredictionModel'
import { McdPotentialModel } from '@placeme/components/organisms/Tiles/Mcd/McdPotentialModel/McdPotentialModel'
import { DemoPotentialWithinChain } from '@placeme/components/organisms/Tiles/Potential/PotentialWithinChain/DemoPotentialWithinChain'
import { SegmentationTile } from '@placeme/components/organisms/Tiles/BigBen/Segmentiation/SegmentationTile'
import { ReactComponent as InhabitantsIcon } from '@dataplace.ai/assets/lib/icons/placeme/inhabitantsVisitors.svg'
import LocationType from '@placeme/components/organisms/Tiles/Population/LocationType/LocationType'
import { DemoStoreChainImpact } from '@placeme/components/organisms/Tiles/Mcd/StoreChainImpact/DemoStoreChainImpact/DemoStoreChainImpact'
import OverlappingRanges from '@placeme/components/organisms/Tiles/Population/OverlappingRanges/OverlappingRanges'
import DolSapPotentialWithinChain from '@placeme/components/organisms/Tiles/DolSap/PotentialWithinChain/DolSapPotentialWithinChain'
import { ISectionTile } from '../slice/@types/ISectionTile'
import { PeopleTile } from '../components/organisms/Tiles/Population/People'
import { DemographyTile } from '../components/organisms/Tiles/Population/Demography'
import { SpendingTile } from '../components/organisms/Tiles/Population/Spending'
import { MunicipalityPopulationTile } from '../components/organisms/Tiles/Population/MunicipalityPopulation'
import { PurchasingPowerTile } from '../components/organisms/Tiles/Population/PurchasingPower'
import { ParkingTile } from '../components/organisms/Tiles/AccessLogistics/Parking'
import { AccessRangeTile } from '../components/organisms/Tiles/AccessLogistics/AccessRange'
import { TransportTile } from '../components/organisms/Tiles/AccessLogistics/Transport'
import { CannibalizationTile } from '../components/organisms/Tiles/Market/Cannibalization'
import { GravityModelTile } from '../components/organisms/Tiles/Potential/GravityModel'
import InvestmentsNew from '../components/organisms/Tiles/Surroundings/Investments/InvestmentsNew'
import { InvestmentsTile } from '../components/organisms/Tiles/Surroundings/Investments'
import { LocalMarketShareTile } from '../components/organisms/Tiles/Potential/LocalMarketShare'
import { PoisTile } from '../components/organisms/Tiles/Market/Pois'
import { BuildingsTile } from '../components/organisms/Tiles/Surroundings/Buildings'
import { VisitingHoursTile } from '../components/organisms/Tiles/Traffic/VisitingHours'
import { TrafficVisualisationTile } from '../components/organisms/Tiles/Traffic/TrafficVisualisation'
import { CustomerOriginTile } from '../components/organisms/Tiles/Traffic/CustomerOrigin'
import { LandUsePlanTile } from '../components/organisms/Tiles/Visualization/LandUsePlan'
import { CitiesPopulationTile } from '../components/organisms/Tiles/Visualization/CitiesPopulation'
import { TrafficVisualisationMapTile } from '../components/organisms/Tiles/Visualization/TrafficVisualisationMap'
import { TrafficDataSet, InhabitantsDataSet, SpendingDataSet,
  PoisDataSet, PurchasingPowerDataSet, PopulationHeatmapDataSet, MunicipalityPopulationDataSet } from './dataSetUpdates'
import { PopulationAndSpendingTile } from '../components/organisms/Tiles/BigBen/PopulationAndSpending'
import { TrafficTile } from '../components/organisms/Tiles/BigBen/Traffic'
import { CompetitionAndSurroundingsTile } from '../components/organisms/Tiles/BigBen/CompetitionAndSurroundings'
import { SimilarLocationTile } from '../components/organisms/Tiles/BigBen/SimilarLocation'
import { PotentialTile } from '../components/organisms/Tiles/BigBen/Potential'
import { SalesPotentialTile } from '../components/organisms/Tiles/Potential/SalesPotential'
import { PopulationHeatmapTile } from '../components/organisms/Tiles/Visualization/PopulationHeatmap'
import { SatelliteTile } from '../components/organisms/Tiles/Visualization/Satellite'
import { EmployeesInRangeTile } from '../components/organisms/Tiles/Surroundings/EmployeesInRange'
import { TrafficVisualisationBigBenTile } from '../components/organisms/Tiles/BigBen/TrafficVisualisation'
import { CompetitionTile } from '../components/organisms/Tiles/Mcd/CompetitionTrafficTile'
import { StoreChainImpact } from '../components/organisms/Tiles/Mcd/StoreChainImpact/StoreChainImpact/StoreChainImpact'
import { CompetitionWithinReach } from '../components/organisms/Tiles/Anw/CompetitionWithinReach/CompetitionWithinReach'
import { defaultMaxRanges } from './constants'

const theMostUsedMaxRanges = {
  car: 15,
  bike: 30,
  walk: 30,
  line: 4000,
  custom: 150000000,
}

export const tilesData: ISectionTile[] = [
  {
    id: 'inhabitants_and_visitors',
    label: 'placeme.category.inhabitants_and_visitors',
    isCategoryOpen: false,
    tiles: [
      {
        id: 'people',
        label: 'placeme.tile.people_in_area',
        labelForeign: 'placeme.tile.people.foreign',
        labelHint: 'placeme.label_hint.people',
        component: PeopleTile,
        canChooseRange: true,
        isExtraPaid: false,
        maxRanges:  {
          car: 15,
          walk: 25,
          bike: 15,
          line: 3000,
          custom: defaultMaxRanges.custom,
        },
        helpers: [
          'placeme.people_tile.helper_1',
          'placeme.people_tile.helper_2',
          'placeme.people_tile.helper_3',
        ],
        hints: [
          {
            content: 'placeme.people_tile.hint_1_content',
            example: 'placeme.people_tile.hint_1_example',
          },
          {
            content: 'placeme.people_tile.hint_2_content',
            example: 'placeme.people_tile.hint_2_example',
          },
          {
            content: 'placeme.people_tile.hint_3_content',
            example: 'placeme.people_tile.hint_3_example',
          },
          {
            content: 'placeme.people_tile.hint_4_content',
            example: 'placeme.people_tile.hint_4_example',
          },
        ],
        dataSetUpdates: [
          TrafficDataSet,
          InhabitantsDataSet,
          PopulationHeatmapDataSet,
        ],
        section: 'inhabitants_and_visitors',
        demoImageKey: 'placeme.analyse.sidebar.trial.people.demo_image',
      },
      {
        id: 'location_type',
        label: 'placeme.tile.location_type',
        labelHint: 'placeme.label_hint.location_type',
        acceptDescription: 'placeme.location_type.accept_description',
        component: LocationType,
        canChooseRange: false,
        isExtraPaid: false,
        haveToAccept: true,
        maxRanges:  {
          car: 15,
          walk: 25,
          bike: 15,
          line: 3000,
          custom: defaultMaxRanges.custom,
        },
        helpers: [
          'placeme.location_type_tile.helper_1',
          'placeme.location_type_tile.helper_2',
          'placeme.location_type_tile.helper_3',
        ],
        hints: [
          {
            content: 'placeme.location_type_tile.hint_1_content',
            example: 'placeme.location_type_tile.hint_1_example',
          },
        ],
        dataSetUpdates: [],
        section: 'inhabitants_and_visitors',
      },
      {
        id: 'overlapping_ranges',
        label: 'placeme.tile.overlapping_ranges',
        labelHint: 'placeme.label_hint.overlapping_ranges',
        component: OverlappingRanges,
        canChooseRange: false,
        isExtraPaid: false,
        maxRanges: defaultMaxRanges,
        helpers: ['placeme.overlapping_ranges_tile.helper_1'],
        hints: [
          {
            content: 'placeme.overlapping_ranges_tile.hint_1_content',
            example: 'placeme.overlapping_ranges_tile.hint_1_example',
          },
        ],
        dataSetUpdates: [],
        section: 'inhabitants_and_visitors',
      },
    ],
    icon:
  <InhabitantsVisitorsIcon
    height={20}
    width={20}
  />,
  },
  {
    id: 'population',
    label: 'placeme.category.population',
    isCategoryOpen: false,
    tiles: [
      {
        id: 'demography',
        label: 'placeme.tile.demography',
        labelHint: 'placeme.label_hint.demography',
        component: DemographyTile,
        canChooseRange: true,
        maxRanges:  {
          car: 15,
          walk: 30,
          bike: 15,
          line: 3000,
          custom: defaultMaxRanges.custom,
        },
        helpers: ['placeme.demography_tile.helper_1'],
        isExtraPaid: false,
        hints: [
          {
            content: 'placeme.demography_tile.hint_1_content',
            example: 'placeme.demography_tile.hint_1_example',
          },
          {
            content: 'placeme.demography_tile.hint_2_content',
            example: 'placeme.demography_tile.hint_2_example',
          },
        ],
        dataSetUpdates: [
          TrafficDataSet,
          InhabitantsDataSet,
          PopulationHeatmapDataSet,
        ],
        section: 'population',
        demoImageKey: 'placeme.analyse.sidebar.trial.demography.demo_image',
      },
      {
        id: 'spending',
        label: 'placeme.tile.expanses',
        labelHint: 'placeme.label_hint.expanses',
        component: SpendingTile,
        canChooseRange: true,
        isExtraPaid: false,
        maxRanges:  {
          car: 15,
          walk: 30,
          bike: 15,
          line: 3000,
          custom: defaultMaxRanges.custom,
        },
        helpers: ['placeme.spending_tile.helper_1'],
        hints: [
          {
            content: 'placeme.spending_tile.hint_1_content',
            example: 'placeme.spending_tile.hint_1_example',
          },
          {
            content: 'placeme.spending_tile.hint_2_content',
            example: 'placeme.spending_tile.hint_2_example',
          },
        ],
        dataSetUpdates: [
          SpendingDataSet,
        ],
        section: 'population',
        demoImageKey: 'placeme.analyse.sidebar.trial.spending.demo_image',
      },
      {
        id: 'purchasing_power',
        label: 'placeme.tile.purchasing_power',
        labelHint: 'placeme.label_hint.purchasing_power',
        component: PurchasingPowerTile,
        canChooseRange: true,
        isExtraPaid: false,
        maxRanges:  {
          car: 15,
          walk: 30,
          bike: 15,
          line: 3000,
          custom: defaultMaxRanges.custom,
        },
        helpers: ['placeme.purchasing_power_tile.helper_1'],
        hints: [
          {
            content: 'placeme.purchasing_power_tile.hint_1_content',
            example: 'placeme.purchasing_power_tile.hint_1_example',
          },
          {
            content: 'placeme.purchasing_power_tile.hint_2_content',
            example: 'placeme.purchasing_power_tile.hint_2_example',
          },
        ],
        dataSetUpdates: [
          PurchasingPowerDataSet,
        ],
        section: 'population',
        demoImageKey: 'placeme.analyse.sidebar.trial.purchasing_power.demo_image',
      },
    ],
    icon:
  <InhabitantsIcon
    height={20}
    width={20}
  />,
  },
  {
    id: 'traffic',
    label: 'placeme.category.traffic',
    isCategoryOpen: false,
    tiles: [
      {
        id: 'traffic_visualisation',
        canChooseRange: true,
        isExtraPaid: false,
        component: TrafficVisualisationTile,
        helpers: ['placeme.traffic_visualisation_tile.helper_1'],
        label: 'placeme.tile.traffic_visualisation',
        labelHint: 'placeme.label_hint.traffic_visualisation',
        maxRanges:  {
          car: 15,
          bike: 20,
          walk: 30,
          line: 4000,
          custom: defaultMaxRanges.custom,
        },
        hints: [
          {
            content: 'placeme.traffic_visualisation_tile.hint_1_content',
            example: 'placeme.traffic_visualisation_tile.hint_1_example',
          },
          {
            content: 'placeme.traffic_visualisation_tile.hint_2_content',
            example: 'placeme.traffic_visualisation_tile.hint_2_example',
          },
        ],
        dataSetUpdates: [
          TrafficDataSet,
          InhabitantsDataSet,
          PopulationHeatmapDataSet,
        ],
        section: 'traffic',
        demoImageKey: 'placeme.analyse.sidebar.trial.traffic_visualisation.demo_image',
      },
      {
        id: 'visiting_hours',
        component: VisitingHoursTile,
        canChooseRange: true,
        isExtraPaid: false,
        helpers: ['placeme.visiting_hours_tile.helper_1'],
        label: 'placeme.tile.visiting_hours',
        labelHint: 'placeme.label_hint.visiting_hours',
        maxRanges:theMostUsedMaxRanges,
        hints: [
          {
            content: 'placeme.visiting_hours_tile.hint_1_content',
            example: 'placeme.visiting_hours_tile.hint_1_example',
          },
          {
            content: 'placeme.visiting_hours_tile.hint_2_content',
            example: 'placeme.visiting_hours_tile.hint_2_example',
          },
        ],
        dataSetUpdates: [
          TrafficDataSet,
          InhabitantsDataSet,
          PopulationHeatmapDataSet,
        ],
        section: 'traffic',
        demoImageKey: 'placeme.analyse.sidebar.trial.visiting_hours.demo_image',
      },
      {
        id: 'customer_origin',
        canChooseRange: false,
        isExtraPaid: false,
        maxRanges: defaultMaxRanges,
        component: CustomerOriginTile,
        helpers: ['placeme.customer_origin_tile.helper_1'],
        label: 'placeme.tile.customer_origin',
        labelHint: 'placeme.label_hint.customer_origin',
        hints: [
          {
            content: 'placeme.customer_origin_tile.hint_1_content',
            example: 'placeme.customer_origin_tile.hint_1_example',
          },
          {
            content: 'placeme.customer_origin_tile.hint_2_content',
            example: 'placeme.customer_origin_tile.hint_2_example',
          },
        ],
        dataSetUpdates: [
          TrafficDataSet,
          InhabitantsDataSet,
          PopulationHeatmapDataSet,
        ],
        section: 'traffic',
        demoImageKey: 'placeme.analyse.sidebar.trial.customer_origin.demo_image',
      },
    ],
    icon:
  <TrafficIcon
    className='traffic-icon'
    height={20}
    width={20}
  />,
  },
  {
    id: 'market',
    label: 'placeme.category.market',
    isCategoryOpen: false,
    tiles: [
      {
        id: 'pois',
        component: PoisTile,
        canChooseRange: true,
        isExtraPaid: false,
        helpers: ['placeme.pois_tile.helper_1'],
        label: 'placeme.tile.pois_tile',
        labelHint: 'placeme.label_hint.pois',
        maxRanges: {
          car: 15,
          bike: 20,
          walk: 30,
          line: 4000,
          custom: defaultMaxRanges.custom,
        },
        hints: [
          {
            content: 'placeme.pois_tile.hint_1_content',
            example: 'placeme.pois_tile.hint_1_example',
          },
          {
            content: 'placeme.pois_tile.hint_2_content',
            example: 'placeme.pois_tile.hint_2_example',
          },
        ],
        dataSetUpdates: [
          PoisDataSet,
        ],
        section: 'market',
        demoImageKey: 'placeme.analyse.sidebar.trial.pois.demo_image',
      },
      {
        id: 'cannibalization',
        component: CannibalizationTile,
        canChooseRange: false,
        isExtraPaid: false,
        chosenRange: {
          id: 'line250',
          type: 'line',
          value: 250,
        },
        helpers: ['placeme.cannibalization_tile.helper_1'],
        label: 'placeme.tile.cannibalization',
        labelHint: 'placeme.label_hint.cannibalization',
        hints: [
          {
            content: 'placeme.cannibalization_tile.hint_1_content',
            example: 'placeme.cannibalization_tile.hint_1_example',
          },
          {
            content: 'placeme.cannibalization_tile.hint_2_content',
            example: 'placeme.cannibalization_tile.hint_2_example',
          },
        ],
        dataSetUpdates: [
          TrafficDataSet,
          InhabitantsDataSet,
          PopulationHeatmapDataSet,
        ],
        section: 'market',
        demoImageKey: 'placeme.analyse.sidebar.trial.cannibalization.demo_image',
      },
    ],
    icon:
  <MarketIcon
    height={20}
    width={20}
  />,
  },
  {
    id: 'surroundings',
    label: 'placeme.category.surroundings',
    isCategoryOpen: false,
    tiles: [
      {
        id: 'buildings',
        canChooseRange: true,
        isExtraPaid: false,
        component: BuildingsTile,
        helpers: ['placeme.buildings_tile.helper_1'],
        label: 'placeme.tile.buildings',
        labelHint: 'placeme.label_hint.buildings',
        maxRanges:  {
          car: 15,
          bike: 20,
          walk: 30,
          line: 4000,
          custom: defaultMaxRanges.custom,
        },
        hints: [
          {
            content: 'placeme.buildings_tile.hint_1_content',
            example: 'placeme.buildings_tile.hint_1_example',
          },
          {
            content: 'placeme.buildings_tile.hint_2_content',
            example: 'placeme.buildings_tile.hint_2_example',
          },
          {
            content: 'placeme.buildings_tile.hint_3_content',
            example: 'placeme.buildings_tile.hint_3_example',
          },
        ],
        dataSetUpdates: [
          TrafficDataSet,
          InhabitantsDataSet,
          PopulationHeatmapDataSet,
        ],
        section: 'surroundings',
        demoImageKey: 'placeme.analyse.sidebar.trial.buildings.demo_image',
      },
      {
        id: 'investments',
        component: InvestmentsTile,
        canChooseRange: true,
        isExtraPaid: false,
        helpers: ['placeme.investments_tile.helper_1'],
        label: 'placeme.tile.investment_plans',
        labelHint: 'placeme.label_hint.investment_plans',
        maxRanges: {
          car: 15,
          bike: 20,
          walk: 30,
          line: 4000,
          custom: defaultMaxRanges.custom,
        },
        hints: [
          {
            content: 'placeme.investments_tile.hint_1_content',
            example: 'placeme.investments_tile.hint_1_example',
          },
          {
            content: 'placeme.investments_tile.hint_2_content',
            example: 'placeme.investments_tile.hint_2_example',
          },
        ],
        dataSetUpdates: [
          TrafficDataSet,
          InhabitantsDataSet,
          PopulationHeatmapDataSet,
        ],
        section: 'surroundings',
        demoImageKey: 'placeme.analyse.sidebar.trial.investments.demo_image',
      },
      {
        id: 'investments_new',
        component: InvestmentsNew,
        canChooseRange: true,
        isExtraPaid: false,
        helpers: ['placeme.investments_tile.helper_1'],
        label: 'placeme.tile.investment_plans',
        labelHint: 'placeme.label_hint.investment_plans',
        maxRanges: {
          car: 15,
          bike: 20,
          walk: 30,
          line: 4000,
          custom: defaultMaxRanges.custom,
        },
        hints: [
          {
            content: 'placeme.investments_tile.hint_1_content',
            example: 'placeme.investments_tile.hint_1_example',
          },
          {
            content: 'placeme.investments_tile.hint_2_content',
            example: 'placeme.investments_tile.hint_2_example',
          },
          {
            content: 'placeme.investments_tile.hint_3_content',
            example: 'placeme.investments_tile.hint_3_example',
          },
        ],
        dataSetUpdates: [
          TrafficDataSet,
          InhabitantsDataSet,
          PopulationHeatmapDataSet,
        ],
        section: 'surroundings',
        demoImageKey: 'placeme.analyse.sidebar.trial.investments.demo_image',
      },
      {
        id: 'employees_in_range',
        component: EmployeesInRangeTile,
        canChooseRange: true,
        isExtraPaid: false,
        helpers: ['placeme.employees_in_range_tile.helper_1'],
        label: 'placeme.tile.employees_in_range',
        labelHint: 'placeme.label_hint.employees_in_range',
        maxRanges: defaultMaxRanges,
        hints: [
          {
            content: 'placeme.employees_in_range_tile.hint_1_content',
            example: 'placeme.employees_in_range_tile.hint_1_example',
          },
        ],
        dataSetUpdates: [
          TrafficDataSet,
          InhabitantsDataSet,
          PopulationHeatmapDataSet,
        ],
        section: 'surroundings',
        demoImageKey: 'placeme.analyse.sidebar.trial.employees_in_range.demo_image',
      },
      {
        id: 'municipality_population',
        label: 'placeme.tile.municipality_population',
        labelHint: 'placeme.label_hint.municipality_population',
        component: MunicipalityPopulationTile,
        canChooseRange: false,
        isExtraPaid: false,
        haveToAccept: true,
        acceptDescription: 'placeme.municipality_population.accept_description',
        maxRanges: defaultMaxRanges,
        helpers: ['placeme.municipality_population_tile.helper_1'],
        hints: [
          {
            content: 'placeme.municipality_population_tile.hint_1_content',
            example: 'placeme.municipality_population_tile.hint_1_example',
          },
        ],
        dataSetUpdates: [
          MunicipalityPopulationDataSet,
        ],
        section: 'surroundings',
        demoImageKey: 'placeme.analyse.sidebar.trial.municipality_population.demo_image',
      },
    ],
    icon:
  <SurroundingsIcon
    height={20}
    width={20}
  />,
  },
  {
    id: 'access_logistics',
    label: 'placeme.category.access_logistics',
    isCategoryOpen: false,
    tiles: [
      {
        id: 'transport',
        label: 'placeme.tile.transport',
        labelHint: 'placeme.label_hint.transport',
        component: TransportTile,
        canChooseRange: false,
        isExtraPaid: false,
        maxRanges: defaultMaxRanges,
        helpers: ['placeme.transport_tile.helper_1'],
        hints: [
          {
            content: 'placeme.transport_tile.hint_1_content',
            example: 'placeme.transport_tile.hint_1_example',
          },
          {
            content: 'placeme.transport_tile.hint_2_content',
            example: 'placeme.transport_tile.hint_2_example',
          },
        ],
        dataSetUpdates: [
          TrafficDataSet,
          InhabitantsDataSet,
          PopulationHeatmapDataSet,
        ],
        section: 'access_logistics',
        demoImageKey: 'placeme.analyse.sidebar.trial.transport.demo_image',
      },
      {
        id: 'access_range',
        label: 'placeme.tile.access_range',
        labelHint: 'placeme.label_hint.access_range',
        component: AccessRangeTile,
        canChooseRange: false,
        isExtraPaid: false,
        maxRanges: defaultMaxRanges,
        helpers: ['placeme.access_range_tile.helper_1'],
        hints: [
          {
            content: 'placeme.access_range_tile.hint_1_content',
            example: 'placeme.access_range_tile.hint_1_example',
          },
          {
            content: 'placeme.access_range_tile.hint_2_content',
            example: 'placeme.access_range_tile.hint_2_example',
          },
        ],
        dataSetUpdates: [
          TrafficDataSet,
          InhabitantsDataSet,
          PopulationHeatmapDataSet,
        ],
        section: 'access_logistics',
        demoImageKey: 'placeme.analyse.sidebar.trial.access_range.demo_image',
      },
      {
        id: 'parking',
        label: 'placeme.tile.parking',
        labelHint: 'placeme.label_hint.parking',
        component: ParkingTile,
        canChooseRange: true,
        isExtraPaid: false,
        maxRanges: defaultMaxRanges,
        helpers: ['placeme.parking_tile.helper_1'],
        hints: [
          {
            content: 'placeme.parking_tile.hint_1_content',
            example: 'placeme.parking_tile.hint_1_example',
          },
          {
            content: 'placeme.parking_tile.hint_2_content',
            example: 'placeme.parking_tile.hint_2_example',
          },
        ],
        dataSetUpdates: [
          TrafficDataSet,
          InhabitantsDataSet,
          PopulationHeatmapDataSet,
        ],
        section: 'access_logistics',
      },
    ],
    icon:
  <TransportIcon
    height={20}
    width={20}
  />,
  },
  {
    id: 'potential',
    label: 'placeme.category.potential',
    isCategoryOpen: false,
    tiles: [
      {
        id: 'potential_model',
        component: SalesPotentialTile,
        canChooseRange: false,
        isExtraPaid: false,
        maxRanges: defaultMaxRanges,
        helpers: ['placeme.potential_model_tile.helper_1'],
        label: 'placeme.tile.potential_model',
        labelHint: 'placeme.label_hint.sales_potential',
        hints: [
          {
            content: 'placeme.sales_potential_tile.hint_1_content',
            example: 'placeme.sales_potential_tile.hint_1_example',
          },
          {
            content: 'placeme.sales_potential_tile.hint_2_content',
            example: 'placeme.sales_potential_tile.hint_2_example',
          },
        ],
        dataSetUpdates: [
          TrafficDataSet,
          InhabitantsDataSet,
          PopulationHeatmapDataSet,
        ],
        section: 'potential',
        demoImageKey: 'placeme.analyse.sidebar.trial.potential_model.demo_image',
      },
      {
        id: 'gravity_model',
        component: GravityModelTile,
        canChooseRange: true,
        isExtraPaid: false,
        maxRanges: {
          car: 15,
          bike: 30,
          walk: 30,
          line: 4000,
          custom: defaultMaxRanges.custom,
        },
        helpers: ['placeme.gravity_model_tile.helper_1'],
        label: 'placeme.tile.gravity_model',
        labelHint: 'placeme.label_hint.gravity_model',
        hints: [
          {
            content: 'placeme.gravity_model_tile.hint_1_content',
            example: 'placeme.gravity_model_tile.hint_1_example',
          },
          {
            content: 'placeme.gravity_model_tile.hint_2_content',
            example: 'placeme.gravity_model_tile.hint_2_example',
          },
        ],
        dataSetUpdates: [
          TrafficDataSet,
          InhabitantsDataSet,
          PopulationHeatmapDataSet,
        ],
        section: 'potential',
        demoImageKey: 'placeme.analyse.sidebar.trial.gravity_model.demo_image',
      },
      {
        id: 'potential_within_chain',
        component: PotentialWithinChain,
        canChooseRange: false,
        isExtraPaid: false,
        maxRanges: {
          car: 15,
          bike: 30,
          walk: 30,
          line: 4000,
          custom: defaultMaxRanges.custom,
        },
        helpers: [],
        label: 'placeme.tile.potential_within_chain',
        labelHint: 'placeme.label_hint.potential_within_chain',
        hints: [
          {
            content: 'placeme.potential_within_chain_tile.hint_1_content',
            example: 'placeme.potential_within_chain_tile.hint_1_example',
          },
        ],
        dataSetUpdates: [],
        section: 'potential',
      },
      {
        id: 'local_market_share',
        component: LocalMarketShareTile,
        canChooseRange: false,
        isExtraPaid: false,
        chosenRange: {
          id: 'line1000',
          type: 'line',
          value: 1000,
        },
        helpers: ['placeme.local_market_share_tile.helper_1'],
        label: 'placeme.tile.local_market_share',
        labelHint: 'placeme.label_hint.local_market_share',
        hints: [
          {
            content: 'placeme.local_market_share_tile.hint_1_content',
            example: 'placeme.local_market_share_tile.hint_1_example',
          },
          {
            content: 'placeme.local_market_share_tile.hint_2_content',
            example: 'placeme.local_market_share_tile.hint_2_example',
          },
        ],
        dataSetUpdates: [
          TrafficDataSet,
          InhabitantsDataSet,
          PopulationHeatmapDataSet,
        ],
        section: 'potential',
        demoImageKey: 'placeme.analyse.sidebar.trial.local_market_share.demo_image',
      },
    ],
    icon:
  <PotentialIcon
    height={20}
    width={20}
  />,
  },
  {
    id: 'visualization',
    label: 'placeme.category.visualisation',
    isCategoryOpen: false,
    tiles: [
      {
        id: 'cities_population',
        component: CitiesPopulationTile,
        canChooseRange: false,
        isExtraPaid: false,
        maxRanges: defaultMaxRanges,
        helpers: ['placeme.cities_population_tile.helper_1'],
        label: 'placeme.tile.cities_population',
        labelHint: 'placeme.label_hint.cities_population',
        hints: [
          {
            content: 'placeme.cities_population_tile.hint_1_content',
            example: 'placeme.cities_population_tile.hint_1_example',
          },
          {
            content: 'placeme.cities_population_tile.hint_2_content',
            example: 'placeme.cities_population_tile.hint_2_example',
          },
        ],
        dataSetUpdates: [
          TrafficDataSet,
          InhabitantsDataSet,
          PopulationHeatmapDataSet,
        ],
        section: 'visualization',
        demoImageKey: 'placeme.analyse.sidebar.trial.cities_population.demo_image',
      },
      {
        id: 'population_heatmap',
        component: PopulationHeatmapTile,
        canChooseRange: true,
        isExtraPaid: false,
        maxRanges: defaultMaxRanges,
        helpers: ['placeme.population_heatmap_tile.helper_1'],
        label: 'placeme.tile.population_heatmap',
        labelHint: 'placeme.label_hint.population_heatmap',
        hints: [
          {
            content: 'placeme.people_tile.hint_1_content',
            example: 'placeme.people_tile.hint_1_example',
          },
          {
            content: 'placeme.people_tile.hint_2_content',
            example: 'placeme.people_tile.hint_2_example',
          },
          {
            content: 'placeme.people_tile.hint_3_content',
            example: 'placeme.people_tile.hint_3_example',
          },
          {
            content: 'placeme.people_tile.hint_4_content',
            example: 'placeme.people_tile.hint_4_example',
          },
        ],
        dataSetUpdates: [
          TrafficDataSet,
          InhabitantsDataSet,
          PopulationHeatmapDataSet,
        ],
        section: 'visualization',
        demoImageKey: 'placeme.analyse.sidebar.trial.population_heatmap.demo_image',
      },
      {
        id: 'traffic_visualisation_map',
        component: TrafficVisualisationMapTile,
        canChooseRange: true,
        isExtraPaid: false,
        maxRanges:  {
          car: 15,
          bike: 20,
          walk: 30,
          line: 4000,
          custom: defaultMaxRanges.custom,
        },
        helpers: ['placeme.traffic_visualisation__map_tile.helper_1'],
        label: 'placeme.tile.traffic_visualisation_map',
        labelHint: 'placeme.label_hint.traffic_visualisation_map',
        hints: [
          {
            content: 'placeme.traffic_visualisation__map_tile.hint_1_content',
            example: 'placeme.traffic_visualisation__map_tile.hint_1_example',
          },
        ],
        dataSetUpdates: [
          TrafficDataSet,
          InhabitantsDataSet,
          PopulationHeatmapDataSet,
        ],
        section: 'visualization',
        demoImageKey: 'placeme.analyse.sidebar.trial.traffic_visualisation_map.demo_image',
      },
      {
        id: 'land_use_plan',
        component: LandUsePlanTile,
        canChooseRange: false,
        isExtraPaid: false,
        helpers: ['placeme.land_use_plan_tile.helper_1'],
        label: 'placeme.tile.land_use_plan',
        labelHint: 'placeme.label_hint.land_use_plan',
        maxRanges: theMostUsedMaxRanges,
        hints: [
          {
            content: 'placeme.land_use_plan_tile.hint_1_content',
            example: 'placeme.land_use_plan_tile.hint_1_example',
          },
        ],
        dataSetUpdates: [
          TrafficDataSet,
          InhabitantsDataSet,
          PopulationHeatmapDataSet,
        ],
        section: 'visualization',
        demoImageKey: 'placeme.analyse.sidebar.trial.land_use_plan.demo_image',
      },
      {
        id: 'satellite',
        component: SatelliteTile,
        canChooseRange: false,
        isExtraPaid: false,
        helpers: ['placeme.satellite_tile.helper_1'],
        label: 'placeme.tile.satellite',
        labelHint: 'placeme.label_hint.satellite',
        hints: [],
        dataSetUpdates: [
          TrafficDataSet,
          InhabitantsDataSet,
          PopulationHeatmapDataSet,
        ],
        section: 'visualization',
        demoImageKey: 'placeme.analyse.sidebar.trial.satellite.demo_image',
      },
    ],
    icon:
  <VisualizationIcon
    height={20}
    width={20}
  />,
  },
  {
    id: 'big_ben',
    label: 'placeme.category.big_ben',
    isCategoryOpen: false,
    tiles: [
      {
        id: 'population_big_ben',
        component: PopulationAndSpendingTile,
        canChooseRange: true,
        isExtraPaid: false,
        maxRanges: {
          car: 15,
          walk: 25,
          bike: 15,
          line: 3000,
          custom: defaultMaxRanges.custom,
        },
        helpers: ['placeme.population_and_spending_tile.helper_1'],
        label: 'placeme.tile.population_and_spending',
        labelHint: 'placeme.label_hint.population_and_spending',
        hints: [],
        dataSetUpdates: [
          TrafficDataSet,
        ],
        section: 'big_ben',
        demoImageKey: 'placeme.analyse.sidebar.trial.population_big_ben.demo_image',
      },
      {
        id: 'traffic_big_ben',
        component: TrafficTile,
        canChooseRange: true,
        helpers: ['placeme.big_ben_traffic_tile.helper_1'],
        label: 'placeme.tile.big_ben_traffic',
        labelHint: 'placeme.label_hint.big_ben_traffic',
        isExtraPaid: false,
        maxRanges: {
          car: 15,
          walk: 25,
          bike: 15,
          line: 3000,
          custom: defaultMaxRanges.custom,
        },
        hints: [],
        dataSetUpdates: [
          TrafficDataSet,
        ],
        section: 'big_ben',
        demoImageKey: 'placeme.analyse.sidebar.trial.traffic_big_ben.demo_image',
      },
      {
        id: 'pois_big_ben',
        component: CompetitionAndSurroundingsTile,
        canChooseRange: true,
        isExtraPaid: false,
        maxRanges: {
          car: 15,
          walk: 25,
          bike: 15,
          line: 3000,
          custom: defaultMaxRanges.custom,
        },
        helpers: ['placeme.competition_and_surroundings_tile.helper_1'],
        label: 'placeme.tile.competition_and_surroundings',
        labelHint: 'placeme.label_hint.competition_and_surroundings',
        hints: [],
        dataSetUpdates: [
          TrafficDataSet,
        ],
        section: 'big_ben',
        demoImageKey: 'placeme.analyse.sidebar.trial.pois_big_ben.demo_image',
      },
      {
        id: 'segmentation_big_ben',
        component: SegmentationTile,
        canChooseRange: false,
        isExtraPaid: false,
        haveToAccept: true,
        acceptDescription: 'placeme.segmentation.info',
        helpers: ['placeme.segmentation_tile.helper_1'],
        label: 'placeme.tile.segmentation_big_ben',
        labelHint: 'placeme.label_hint.segmentation',
        hints: [],
        dataSetUpdates: [
          TrafficDataSet,
        ],
        section: 'big_ben',
        demoImageKey: 'placeme.analyse.sidebar.trial.segmentation.demo_image',
      },
      {
        id: 'look_alike_big_ben',
        component: SimilarLocationTile,
        canChooseRange: false,
        isExtraPaid: false,
        haveToAccept: true,
        acceptDescription: 'placeme.similar_location.info',
        helpers: ['placeme.similar_location_tile.helper_1'],
        label: 'placeme.tile.similar_location',
        labelHint: 'placeme.label_hint.similar_location',
        hints: [],
        dataSetUpdates: [
          TrafficDataSet,
        ],
        section: 'big_ben',
        demoImageKey: 'placeme.analyse.sidebar.trial.look_alike_big_ben.demo_image',
      },
      {
        id: 'scoring_model_big_ben',
        component: PotentialTile,
        canChooseRange: false,
        isExtraPaid: false,
        helpers: ['placeme.big_ben_potential_tile.helper_1'],
        label: 'placeme.tile.big_ben_potential',
        labelHint: 'placeme.label_hint.big_ben_potential',
        hints: [],
        dataSetUpdates: [
          TrafficDataSet,
        ],
        section: 'big_ben',
        demoImageKey: 'placeme.analyse.sidebar.trial.scoring_model_big_ben.demo_image',
      },
      {
        id: 'traffic_visualisation_big_ben',
        canChooseRange: true,
        isExtraPaid: false,
        component: TrafficVisualisationBigBenTile,
        helpers: ['placeme.traffic_visualisation_tile.helper_1'],
        label: 'placeme.tile.traffic_visualisation',
        labelHint: 'placeme.label_hint.traffic_visualisation',
        maxRanges:  {
          car: 15,
          bike: 20,
          walk: 30,
          line: 4000,
          custom: defaultMaxRanges.custom,
        },
        hints: [
          {
            content: 'placeme.traffic_visualisation_tile.hint_1_content',
            example: 'placeme.traffic_visualisation_tile.hint_1_example',
          },
          {
            content: 'placeme.traffic_visualisation_tile.hint_2_content',
            example: 'placeme.traffic_visualisation_tile.hint_2_example',
          },
        ],
        dataSetUpdates: [
          TrafficDataSet,
          InhabitantsDataSet,
          PopulationHeatmapDataSet,
        ],
        section: 'big_ben',
        demoImageKey: 'placeme.analyse.sidebar.trial.traffic_visualisation_big_ben.demo_image',
      },
      {
        id: 'old_traffic_visualisation_big_ben',
        canChooseRange: true,
        isExtraPaid: false,
        component: TrafficVisualisationBigBenTile,
        helpers: ['placeme.traffic_visualisation_tile.helper_1'],
        label: 'placeme.tile.old_traffic_visualisation',
        labelHint: 'placeme.label_hint.traffic_visualisation',
        maxRanges:  {
          car: 15,
          bike: 20,
          walk: 30,
          line: 4000,
          custom: defaultMaxRanges.custom,
        },
        hints: [
          {
            content: 'placeme.traffic_visualisation_tile.hint_1_content',
            example: 'placeme.traffic_visualisation_tile.hint_1_example',
          },
          {
            content: 'placeme.traffic_visualisation_tile.hint_2_content',
            example: 'placeme.traffic_visualisation_tile.hint_2_example',
          },
        ],
        dataSetUpdates: [
          TrafficDataSet,
          InhabitantsDataSet,
          PopulationHeatmapDataSet,
        ],
        section: 'big_ben',
        demoImageKey: 'placeme.analyse.sidebar.trial.traffic_visualisation_big_ben.demo_image',
      },
    ],
    icon:
  <BigBenIcon
    height={20}
    width={20}
  />,
  },
  {
    id: 'mcd',
    label: 'placeme.category.mcd',
    isCategoryOpen: false,
    tiles: [
      {
        id: 'mcd_potential_model',
        component: McdPotentialModel,
        canChooseRange: false,
        isExtraPaid: false,
        maxRanges: defaultMaxRanges,
        helpers: ['placeme.mcd_potential_model_tile.helper_1'],
        label: 'placeme.tile.mcd_potential_model',
        labelHint: 'placeme.label_hint.mcd_potential',
        hints: [
          {
            content: 'placeme.mcd_potential.hint_1_content',
            example: 'placeme.mcd_potential.hint_1_example',
          },
        ],
        section: 'mcd',
      },
      {
        id: 'mcd_store_chain_impact',
        component: StoreChainImpact,
        canChooseRange: false,
        isExtraPaid: false,
        haveToAccept: true,
        acceptDescription: 'placeme.mcd_store_chain_impact.accept_description',
        helpers: ['placeme.mcd_store_chain_impact.helper_1'],
        label: 'placeme.tile.mcd_store_chain_impact',
        labelHint: 'placeme.label_hint.mcd_store_chain_impact',
        hints: [
          {
            content: 'placeme.mcd_store_chain_impact.hint_1_content',
            example: 'placeme.mcd_store_chain_impact.hint_1_example',
          },
        ],
        section: 'mcd',
        showRangeDescription: false,
      },
      {
        id: 'mcd_competition_traffic',
        component: CompetitionTile,
        canChooseRange: false,
        isExtraPaid: false,
        haveToAccept: true,
        acceptDescription: 'placeme.mcd_competition_traffic.accept_description_2',
        defaultRange: {
          id: 'mcd_competition_traffic',
          value: 30000,
          type: 'line',
        },
        helpers: ['placeme.mcd_competition_traffic.helper_1'],
        label: 'placeme.tile.mcd_competition_traffic',
        labelHint: 'placeme.label_hint.mcd_competition_traffic',
        hints: [
          {
            content: 'placeme.mcd_competition_traffic.hint_1_content',
            example: 'placeme.mcd_competition_traffic.hint_1_example',
          },
        ],
        dataSetUpdates: [
          TrafficDataSet,
        ],
        section: 'mcd',
        showRangeDescription: true,
      },
    ],
    icon:
  <McdIcon
    height={20}
    width={20}
  />,
  },
  {
    id: 'anw',
    label: 'placeme.category.anw',
    isCategoryOpen: false,
    tiles: [
      {
        id: 'anw_competition',
        component: CompetitionWithinReach,
        canChooseRange: true,
        isExtraPaid: false,
        helpers: ['placeme.anw_competition_within_reach.helper_1'],
        label: 'placeme.tile.anw_competition_within_reach',
        labelHint: 'placeme.label_hint.anw_competition_within_reach',
        maxRanges: {
          car: 15,
          bike: 20,
          walk: 30,
          line: 4000,
          custom: defaultMaxRanges.custom,
        },
        hints: [
          {
            content: 'placeme.anw_competition_within_reach.hint_1_content',
            example: 'placeme.anw_competition_within_reach.hint_1_example',
          },
        ],
        dataSetUpdates: [],
        section: 'anw',
      },
      {
        id: 'anw_potential_model',
        component: AnwPotentialModel,
        canChooseRange: false,
        isExtraPaid: false,
        helpers: ['placeme.anw_potential_model.helper_1'],
        label: 'placeme.tile.anw_potential_model',
        labelHint: 'placeme.label_hint.anw_potential_model',
        maxRanges: {
          car: 15,
          bike: 20,
          walk: 30,
          line: 4000,
          custom: defaultMaxRanges.custom,
        },
        hints: [
          {
            content: 'placeme.anw_potential_model.hint_1_content',
            example: 'placeme.anw_potential_model.hint_1_example',
          },
        ],
        dataSetUpdates: [],
        section: 'anw',
      },
    ],
    icon:
  <AnwIcon
    height={20}
    width={20}
  />,
  },
  {
    id: 'dol_sap',
    label: 'placeme.category.dol_sap',
    isCategoryOpen: false,
    tiles: [
      {
        id: 'dol_sap_potential_within_chain',
        component: DolSapPotentialWithinChain,
        canChooseRange: false,
        isExtraPaid: false,
        maxRanges: {
          car: 15,
          bike: 30,
          walk: 30,
          line: 4000,
          custom: defaultMaxRanges.custom,
        },
        helpers: [],
        label: 'placeme.tile.potential_within_chain',
        labelHint: 'placeme.label_hint.potential_within_chain',
        hints: [
          {
            content: 'placeme.potential_within_chain_tile.hint_1_content',
            example: 'placeme.potential_within_chain_tile.hint_1_example',
          },
        ],
        dataSetUpdates: [],
        section: 'dol_sap',
      },
    ],
    icon:
  <DolSapIcon
    height={20}
    width={20}
  />,
  },
  {
    id: 'demo',
    label: 'placeme.category.demo',
    isCategoryOpen: false,
    tiles: [
      {
        id: 'demo_scoring_prediction',
        component: DemoScoringPrediction,
        canChooseRange: false,
        isExtraPaid: false,
        helpers: ['placeme.demo.demo_scoring_prediction.helper_1'],
        label: 'placeme.demo.tile.demo_scoring_prediction',
        labelHint: 'placeme.demo.label_hint.demo_scoring_prediction',
        maxRanges: {
          car: 15,
          bike: 20,
          walk: 30,
          line: 4000,
          custom: defaultMaxRanges.custom,
        },
        hints: [
          {
            content: 'placeme.demo.demo_scoring_prediction.hint_1_content',
            example: 'placeme.demo.demo_scoring_prediction.hint_1_example',
          },
        ],
        dataSetUpdates: [],
        section: 'demo',
      },
      {
        id: 'demo_sales_prediction_model',
        component: DemoSalesPredictionModel,
        canChooseRange: false,
        isExtraPaid: false,
        maxRanges: defaultMaxRanges,
        helpers: ['placeme.demo_sales_prediction_model.helper_1'],
        label: 'placeme.demo.tile.sales_prediction_model',
        labelHint: 'placeme.label_hint.demo_sales_prediction_model',
        hints: [
          {
            content: 'placeme.demo_sales_prediction_model.hint_1_content',
            example: 'placeme.demo_sales_prediction_model.hint_1_example',
          },
        ],
        section: 'demo',
      },
      {
        id: 'demo_potential_within_chain',
        component: DemoPotentialWithinChain,
        canChooseRange: false,
        isExtraPaid: false,
        maxRanges: {
          car: 15,
          bike: 30,
          walk: 30,
          line: 4000,
          custom: defaultMaxRanges.custom,
        },
        helpers: [],
        label: 'placeme.tile.demo_potential_within_chain',
        labelHint: 'placeme.label_hint.demo_potential_within_chain',
        hints: [
          {
            content: 'placeme.demo_potential_within_chain_tile.hint_1_content',
            example: 'placeme.demo_potential_within_chain_tile.hint_1_example',
          },
        ],
        dataSetUpdates: [],
        section: 'demo',
      },
      {
        id: 'demo_store_chain_impact',
        component: DemoStoreChainImpact,
        canChooseRange: false,
        isExtraPaid: false,
        haveToAccept: true,
        acceptDescription: 'placeme.demo_store_chain_impact.accept_description',
        helpers: ['placeme.demo_store_chain_impact.helper_1'],
        label: 'placeme.tile.demo_store_chain_impact',
        labelHint: 'placeme.label_hint.demo_store_chain_impact',
        hints: [
          {
            content: 'placeme.demo_store_chain_impact.hint_1_content',
            example: 'placeme.demo_store_chain_impact.hint_1_example',
          },
        ],
        section: 'demo',
        showRangeDescription: false,
      },
    ],
    icon:
  <PaymentsIcon
    height={20}
    width={20}
  />,
  },
]
