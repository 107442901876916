import React from 'react'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Tooltip } from '@dataplace.ai/ui-components/atoms'
import { ReactComponent as SaveIcon } from '@dataplace.ai/assets/lib/icons/dataplace/save.svg'
import { ReactComponent as HelpCircle } from '@dataplace.ai/assets/lib/icons/dataplace/help-circle.svg'

interface IWrapperProps {
  isOpen?: boolean
  disabled?: boolean
}

const Wrapper = styled.div<IWrapperProps>(
  ({
    theme, isOpen, disabled,
  }) => {
    const { palette } = theme
    return css`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    cursor: pointer;

    ${disabled && css`
    cursor: default;
    `}

    > svg {
      width: 1rem;
      height: 1rem;
      margin: 0 0.625rem 0 0.125rem;
       ${disabled && css`
       path{
            fill:${palette.light.darkest};
          }
       `}
    }

    ${!isOpen
    && css`
      > svg {
        margin: 0 1.375rem;
      }
    `}
  `
  },
)

const Title = styled.span<{disabled?: boolean}>(({
  theme, disabled,
}) => {
  const {
    palette, typography,
  } = theme
  return css`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    color: ${disabled ? palette.light.darker : palette.blue};
    font-size: ${typography.small.pt_13_medium.fontSize};
    font-weight: ${typography.small.pt_13_medium.fontWeight};
    line-height: ${typography.small.pt_13_medium.lineHeight};
  `
})

const StyledHelpedIcon = styled(HelpCircle)(({ theme }) => css`
    width: 0.8rem;
    height: 0.8rem;
    margin-left: 0.5rem;
    display: grid;
    path {
      fill: ${theme.palette.blue};
    }
`)

interface ISaveTemplateProps {
  isOpen?: boolean
  disabled?: boolean
  style?: React.CSSProperties
}

export const SaveTemplate = ({
  isOpen, disabled, style,
}: ISaveTemplateProps): JSX.Element => {
  const { t } = useTranslation()
  return (
    <Wrapper
      disabled={disabled}
      isOpen={isOpen}
      style={style}
    >
      <SaveIcon />
      {isOpen
        ? (
          <>
            <Title disabled={disabled}>
              {t('placeme.sidebar.save_as_template')}
            </Title>
            <Tooltip
              content={t('placeme.template.save_template.info')}
              maxWidth='300px'
              position='right center'
            >
              <StyledHelpedIcon className='help-circle' />
            </Tooltip>
          </>
        )
        : null}
    </Wrapper>
  )
}

SaveTemplate.defaultProps = {
  isOpen: true,
  disabled: false,
  style: {},
}

