
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import styled, { css } from 'styled-components'
import {
  TileFormSection,
  TileFormWrapper,
  TileFormRowWithData,
  TileFormParagraph, TitleFormSectionSubTitle,
  // Button,
} from '@dataplace.ai/ui-components/atoms'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'apps/placeme/src/redux/hooks'
import { getAxios } from '@dataplace.ai/functions/utils/axios'
import { AnalyticsContext, AuthContext } from '@dataplace.ai/features'
import { ILocation, IRange } from '@dataplace.ai/types'
import Geocoder from 'apps/placeme/src/services/Geocoder/Geocoder'
import { compareLocationCatchmentAndDataAction, deleteTileAction, fetchWorkspaceUsageValue, replaceTileDataAction, saveNewRangeAction, saveTileData } from 'apps/placeme/src/features/Analyse/slice/analysisSlice'
import { createNewAnalyse, fetchSubscriptionInfo } from 'apps/placeme/src/features/ChooseLocationReport/chooseLocationSlice'
import { Loader } from 'libs/shared/ui-components/src/atoms'
import { checkComparedCoinsValue } from 'apps/placeme/src/functions'
import { getWorkspaceIdFromLocalStorage } from '@dataplace.ai/functions/utils'
import { TileSectionIds } from '@dataplace.ai/constants'
import { getTileInfo } from 'apps/placeme/src/functions/getTileInfo'
import { LocalizationSelector, LocalizationSelectorWrapper, TileFooter } from '../../../../atoms'
import { ITileData } from '../../../../../slice/@types/ITileData'
import { ENDPOINTS } from '../../../../../../../constants/endpoints'
import { ICannibalizationTileData } from './@types/ICannibalizationTileData'
import { ChosenLocationCannibalizationTile } from '../../../../molecules'
import { RootState } from '../../../../../../../redux/store'

const StyledTileFormWrapper = styled(TileFormWrapper)(({ theme }) => {
  const { palette } = theme
  return css`
    background-color: ${palette.light.main};
  `
})

const NormalSpan = styled.span(({ theme }) => {
  const { typography } = theme
  return css`
    font-size: ${typography.small.pt_13_regular.fontSize};
    font-weight: ${typography.small.pt_13_regular.fontWeight};
    line-height: ${typography.small.pt_13_regular.lineHeight};
  `
})

export const CannibalizationTile:
React.FC<{data: ICannibalizationTileData, tileId: string, isExtraPaid?: boolean, }> = ({
  data, tileId, isExtraPaid,
}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [localizations, setLocalizations] = useState<ILocation[]>([])
  const [accepted, setAccepted] = useState<boolean>(false)
  // const [loadedLocations, setLoadedLocations] = useState<ICannibalizationRow[]>([])
  const [currentlyLocalizations, setCurrentlyLocalizations] = useState<ILocation[]>([])
  const [localizationsLoading, setLocalizationsLoading] = useState(false)
  const [userId, setUserId] = useState('')
  const [loading, setLoading] = useState(false)
  // const [addingMoreLocations, setAddingMoreLocations] = useState(true)
  const {
    value, analyseId, currentSubscriptionData, comparedAnalyseId,
  } = useSelector((state: RootState) => state.location)
  const {
    values, creditsAmount, canBeSave, comparedLocation,
  } = useSelector((state: RootState) => state.analysis)
  const [token, setToken] = useState('')
  const authContext = useContext(AuthContext)
  const { analytics } = useContext(AnalyticsContext)

  useEffect(() => {
    authContext.userData?.user?.getIdToken()?.then(response => {
      setToken(response)
    })
  }, [authContext])

  // useEffect(() => {
  //   if (data?.value) {
  //     setLoadedLocations(data.value)
  //     setLoading(false)
  //   }
  // }, [data])

  useEffect(() => {
    if (token.length) {
      dispatch(fetchWorkspaceUsageValue())
    }
  }, [token, data])

  useEffect(() => {
    if (authContext.userData?.user?.uid) setUserId(authContext.userData?.user?.uid)
  }, [authContext])

  const tileInfo = useMemo(() => getTileInfo(tileId, TileSectionIds.MARKET, values), [tileId, values])
  const catchmentId = tileInfo?.chosenRange?.catchmentId

  const fetchData = useCallback(async () => {
    const catchmentId = tileInfo?.chosenRange?.catchmentId
    if (accepted) {
      const body = {
        catchmentId,
        addresses: localizations?.map(l => ({
          id: l.placeId,
          address: l.address,
          lat: l.lat,
          lng: l.lng,
        })),
      }
      let saveData
      const axiosInstance = await getAxios({
        errCallbackFn: (e) => {
          saveData = {
            loading: false,
            error: e?.message,
            value: null,
          }
        },
      })
      const response = await axiosInstance.post<ITileData>(ENDPOINTS.CANNIBALIZATION_TILE, body)
      if (response) {
        saveData = {
          loading: false,
          error: '',
          value: response.data,
        }
        if (response.status === 204) {
          window?.localStorage.setItem('noDataModal', catchmentId || 'no catchment')
          window?.dispatchEvent(new CustomEvent('noDataModal'))
        }
      }

      setLoading(false)
      dispatch(saveTileData(TileSectionIds.MARKET, tileId, saveData))
      dispatch(fetchSubscriptionInfo(currentSubscriptionData?.value?.subscriptionId || ''))
    }
  }, [accepted, token, currentSubscriptionData, localizations, values])

  const fetchCurrentlyUsedAddresses = useCallback(async () => {
    let saveData
    const axiosInstance = await getAxios({
      errCallbackFn: (e) => {
        saveData = {
          loading: false,
          error: e.message,
          value: null,
        }
      },
    })
    const response = await axiosInstance.get(
      `/user/${userId}/catchments?workspace_id=${getWorkspaceIdFromLocalStorage()}&filter=coordinates`,
    )

    if (response) {
      saveData = {
        loading: false,
        error: '',
        value: response.data,
      }
    }

    if (saveData?.value?.length) {
      const localizations : ILocation[] = []
      saveData?.value?.forEach((loc: { lat: number; lng: number }) => {
        if (typeof loc?.lat === 'number' && typeof loc?.lng === 'number') {
          const geocoder = new Geocoder()
          let address = ''
          geocoder.reverseGeocode(loc?.lat, loc?.lng).then(async (result) => {
            // eslint-disable-next-line camelcase
            const res = result as { formatted_address: string }[]
            if (result && res.length > 0) {
              address = res[0].formatted_address

              localizations.push({
                placeId: address + loc?.lat + loc?.lng,
                address,
                lat: loc?.lat,
                lng: loc?.lng,
              })
            }
          })
        }
      })

      setCurrentlyLocalizations(localizations)
      setLocalizationsLoading(false)
    }
  }, [accepted, token, userId, getWorkspaceIdFromLocalStorage()])

  const handleFetchComparedData = useCallback(async () => {
    const comparedAnalyseId = window.localStorage.getItem('comparedAnalyseId')
    try {
      // post project - with compared location - create compared analyse
      if (!comparedAnalyseId) await dispatch(createNewAnalyse(analyseId, comparedLocation?.location))
    }
    finally {
      try {
        await dispatch(compareLocationCatchmentAndDataAction(
          token,
          catchmentId || '',
          tileInfo?.chosenRange as IRange,
          tileInfo?.section || '',
          tileInfo?.id || '',
          currentSubscriptionData?.value?.subscriptionId || '',
          comparedLocation?.location as ILocation,
          {
            addresses: localizations?.map(l => ({
              id: l.placeId,
              address: l.address,
              lat: l.lat,
              lng: l.lng,
            })),
          },
        ))
      }
      finally {
        setLoading(false)
      }
    }
  }, [fetchData, catchmentId, !data?.value, accepted, localizations])

  // to do uncomment when backend for compared location ready

  // useEffect(() => {
  //   if (!data?.value?.main?.length) fetchData()
  // }, [fetchData, data?.value?.main?.length])

  useEffect(() => {
    if (!data?.value?.cannibalization?.length) {
      if (catchmentId && !data?.value && accepted) {
        // compared location comparison trigger
        if (comparedLocation?.generatedFromNow && tileInfo?.chosenRange?.type !== 'custom') {
          // compared location
          if (tileInfo && comparedLocation?.location) {
            handleFetchComparedData()
          }
        } else {
          fetchData()
        }
      }
    }
  }, [catchmentId, accepted, data?.value?.cannibalization?.length])

  useEffect(() => {
    if (token && userId && getWorkspaceIdFromLocalStorage() && !data?.value) setLocalizationsLoading(true)
  }, [token, userId, getWorkspaceIdFromLocalStorage()])

  useEffect(() => {
    if (localizationsLoading) {
      fetchCurrentlyUsedAddresses()
    }
  }, [localizationsLoading])

  useEffect(() => {
    if (catchmentId) {
      setAccepted(true)
    }
  }, [catchmentId])

  const handleSave = () => {
    setLoading(true)
    dispatch(saveNewRangeAction(token, authContext.userData.user?.uid || '', TileSectionIds.MARKET, tileId, {
      id: `${tileId}-250-line`,
      value: 250,
      type: 'line',
    }))
  }

  const handleSubmit = () => {
    // setAddingMoreLocations(false)
    if (creditsAmount) {
      const chosenRange = tileInfo?.chosenRange
      analytics?.track('Tile Data Generated', {
        location: {
          ...value,
        },
        tile: tileId?.split('-')[0],
        range: {
          type: chosenRange?.type,
          length: chosenRange?.value,
        },
      })
      handleSave()
    }
  }

  const handleDelete = async () => {
    if (token && analyseId && catchmentId) {
      setLoading(true)
      dispatch(replaceTileDataAction(token, TileSectionIds.MARKET, tileId, comparedAnalyseId || analyseId, setLoading))
    }
  }

  const handleDeleteTile = () => {
    const chosenRange = tileInfo?.chosenRange
    analytics?.track('Tile Cancel Button Clicked', {
      location: {
        ...value,
      },
      tile: tileId?.split('-')[0],
      range: {
        type: chosenRange?.type,
        value: chosenRange?.value,
      },
    })
    dispatch(deleteTileAction(token, TileSectionIds.MARKET, tileId, comparedAnalyseId || analyseId || ''))
  }

  return (
    <>
      { loading
        ? <Loader />
        : (
          <StyledTileFormWrapper>
            <TileFormSection>
              <TileFormRowWithData>
                <span>{t('placeme.cannibalization_tile.row_with_data_1_span_1')}</span>
              </TileFormRowWithData>
              <TileFormParagraph>
                {`${t('placeme.cannibalization_tile.paragraph_1')} ${value?.address}, ${t('placeme.cannibalization_tile.paragraph_2')}`}
              </TileFormParagraph>

              {(!!data?.value?.cannibalization?.length) && (
                <>
                  {data?.value?.cannibalization?.map((localization) => (
                    <ChosenLocationCannibalizationTile
                      key={localization.address}
                      canBeSave={canBeSave}
                      chosenLocalization={localization}
                      comparedAddress={comparedLocation?.location?.address}
                      comparedLocation={data?.value?.comparedLocation?.cannibalization?.find(item =>
                        item?.id === localization?.id)}
                      handleDelete={handleDelete}
                    />
                  ))}
                </>
              )}

              {/* {(!data?.value?.length || addingMoreLocations) && ( */}
              {(!data?.value?.cannibalization?.length) && (
                <>
                  <TitleFormSectionSubTitle>
                    {t('placeme.cannibalization_tile.localization_selector_title')}
                  </TitleFormSectionSubTitle>
                  <LocalizationSelectorWrapper>
                    <NormalSpan>{t('placeme.cannibalization_tile.localization_selector_no_tems')}</NormalSpan>
                    <LocalizationSelector
                      instantAdd
                      localizations={currentlyLocalizations}
                      localizationsLoading={localizationsLoading}
                      multiple
                      onChange={setLocalizations}
                      tile={tileId}
                    />
                  </LocalizationSelectorWrapper>
                </>
              )}

              {/* {(!addingMoreLocations && data?.value?.length) && (
                <AddAnotherRange>
                  <div>
                    <span>{t('placeme.localization_selector.add_another_point_title')}</span>
                    <span>{t('placeme.localization_selector.add_another_point_description')}</span>
                  </div>

                  <Button onClick={() => setAddingMoreLocations(true)}>
                    {t('generic.add')}
                  </Button>
                </AddAnotherRange>
              )} */}

            </TileFormSection>

            {/* {(!data?.value?.length || addingMoreLocations) && ( */}
            {(!data?.value?.cannibalization?.length) && (
              <TileFooter
                disabled={!localizations?.length}
                isExtraPaid={isExtraPaid}
                isUnlimited={currentSubscriptionData?.value?.planExact?.includes('unlimited') || currentSubscriptionData?.value?.plan === 'white'}
                label={isExtraPaid ? t('generic.apply_and_buy') : t('generic.apply')}
                onAccept={handleSubmit}
                onCancel={handleDeleteTile}
                tile='cannibalization'
                value={(localizations.length * checkComparedCoinsValue(
                  comparedLocation?.generatedFromNow,
                  !!comparedLocation?.location,
                  true,
                )).toString()}
              />
            )}

          </StyledTileFormWrapper>
        ) }
    </>
  )
}
