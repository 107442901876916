/* eslint-disable react/require-default-props */
import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Bean, InfoBean } from '@dataplace.ai/ui-components/molecules'
import { ILocation } from '@dataplace.ai/types'
import { IChooseLocationSlice } from 'apps/placeme/src/features/ChooseLocationReport/@types/IChooseLocationSlice'
import NewComparedLocationHeader from '@placeme/components/atoms/ComparedLocationHeader/NewComparedLocationHeader'
import { StyledBigTitle, StyledDescription, StyledHeader, TextContainer, TextRowContainer } from '../Investments.styles'
import { ResidentialData } from '../@types/IInvestmentsTileData'
import InvestmentsList from './InvestmentsList'
import { investmentDeviationTypes, investmentTypes, TOOLTIP_MAX_WIDTH } from '../utils/constants'
import { getDeviation } from '../utils/functions'
import useGetDeviationTranslations from '../hooks/useGetDeviationTranslations'
import useGetNoInvestmentTypeBean from '../hooks/useGetNoInvestmentTypeBean'

interface ResidentialProps {
  residential?: ResidentialData
  comparedResidential?: ResidentialData
  locationData: IChooseLocationSlice['value']
  comparedLocationData?: ILocation
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function Residential({
  residential, comparedResidential, locationData, comparedLocationData,
}: ResidentialProps) {
  const { t } = useTranslation()
  const { getDeviationTranslation } = useGetDeviationTranslations()
  const { getNoInvestmentTypeBean } = useGetNoInvestmentTypeBean({
    investmentsData: residential,
    comparedInvestmentsData: comparedResidential,
    investmentType: investmentTypes.residential,
    address: locationData?.address,
    comparedAddress: comparedLocationData?.address,
  })

  const noInvestmentTypeInfoBean = useMemo(() => getNoInvestmentTypeBean, [getNoInvestmentTypeBean])

  const biggestInvestment = useMemo(() => {
    if (!residential?.list.length) return

    return [...residential.list].sort((a, b) => b.apartmentsShare - a.apartmentsShare)[0]
  }, [residential])

  const biggestComparedInvestment = useMemo(() => {
    if (!comparedResidential?.list.length) return
    return [...comparedResidential?.list].sort((a, b) => b.apartmentsShare - a.apartmentsShare)[0]
  }, [comparedResidential])

  const roundApartmentsShare = useCallback((share: number) => Math.round(share * 100), [])

  const deviations = useMemo(() => {
    if (!residential?.list.length || !comparedResidential?.list.length) return

    return {
      investments: getDeviation(residential.list.length, comparedResidential!.list.length),
      apartmentsCount: getDeviation(residential.apartmentsCount, comparedResidential!.apartmentsCount),
    }
  }, [residential, comparedResidential, getDeviation])

  const deviationsTooltips = useMemo(() => {
    if (!deviations) return

    return {
      investments: getDeviationTranslation({
        deviation: deviations.investments,
        deviationType: investmentDeviationTypes.housingInvestment,
      }),
      apartmentsCount: getDeviationTranslation({
        deviation: deviations.apartmentsCount,
        deviationType: investmentDeviationTypes.apartmentsCount,
      }),
    }
  }, [deviations, getDeviationTranslation])

  return (
    <>
      <TextContainer>
        <TextRowContainer>
          <StyledHeader $semiBold>{t('placeme.investments.housing_potential').toUpperCase()}</StyledHeader>
          {noInvestmentTypeInfoBean}
        </TextRowContainer>
        <StyledDescription $semiBold>{t('placeme.investments.housing_potential.description')}</StyledDescription>
      </TextContainer>
      {residential?.list.length
        ? (
          <>
            <TextContainer>
              <TextRowContainer>
                <StyledHeader>{t('placeme.investments.num_of_investments')}</StyledHeader>
                <StyledBigTitle $semiBold>{residential.list.length}</StyledBigTitle>
                {deviations
                  ? (
                    <Bean
                      tooltipMaxWidth={TOOLTIP_MAX_WIDTH}
                      tooltipText={deviationsTooltips?.investments}
                      value={deviations.investments}
                    />
                  )
                  : null}
              </TextRowContainer>
              <StyledDescription>{t('placeme.investments.num_of_investments.description')}</StyledDescription>
            </TextContainer>
            <TextContainer>
              <TextRowContainer>
                <StyledHeader>{t('placeme.buildings.list.number_of_apartments')}</StyledHeader>
                <StyledBigTitle $semiBold>{residential.apartmentsCount}</StyledBigTitle>
                {deviations
                  ? (
                    <Bean
                      tooltipMaxWidth={TOOLTIP_MAX_WIDTH}
                      tooltipText={deviationsTooltips?.apartmentsCount}
                      value={deviations.apartmentsCount}
                    />
                  )
                  : null}
              </TextRowContainer>
              <StyledDescription>{t('placeme.investments.num_of_apartments.description')}</StyledDescription>
            </TextContainer>
            <TextContainer>
              <TextRowContainer>
                <StyledHeader>{t('placeme.investments.completion_date_of')}</StyledHeader>
                <StyledBigTitle $semiBold>
                  {`${roundApartmentsShare(biggestInvestment!.apartmentsShare)}% ${t('placeme.investments.of_apartments_is')} ${biggestInvestment!.date}`}
                </StyledBigTitle>
                {biggestComparedInvestment
                  ? (
                    <InfoBean
                      tooltipMaxWidth={TOOLTIP_MAX_WIDTH}
                      tooltipText={`
                ${t('placeme.municipality_population.compared_location.header_2')}${' '}
                ${comparedLocationData?.address}${', '}
                ${t('placeme.investments.completion_date_of').toLocaleLowerCase()}${' '}
                ${roundApartmentsShare(biggestComparedInvestment.apartmentsShare)}% ${t('placeme.investments.of_apartments_is')}${' '}
                ${biggestComparedInvestment.date}
              `}
                    />
                  )
                  : null}
              </TextRowContainer>
              <StyledDescription>{t('placeme.investments.completion_date.description')}</StyledDescription>
            </TextContainer>

            {comparedResidential?.list.length
              ? <NewComparedLocationHeader address={locationData?.address} />
              : null}
            <InvestmentsList
              residentialInvestments={residential.list}
              title={t('placeme.investments.list_of_housing_investments')}
            />
          </>
        )
        : null}
      {comparedResidential?.list.length
        ? (
          <>
            <NewComparedLocationHeader
              address={comparedLocationData?.address}
              compared
            />
            <InvestmentsList
              residentialInvestments={comparedResidential.list}
              title={t('placeme.investments.list_of_housing_investments')}
            />
          </>
        )
        : null}
    </>
  )
}

export default Residential
