/* eslint-disable max-lines */

import React, { useCallback, useContext, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import {
  TileFormSection,
  TileFormWrapper,
  TileFormRowWithData,
  TileFormParagraph, TitleFormSectionSubTitle, LinkWithIconCheckOnMap, MapTile,
  // Button,
} from '@dataplace.ai/ui-components/atoms'
import { useTranslation } from 'react-i18next'
import Popup from 'reactjs-popup'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'apps/placeme/src/redux/hooks'
import { getAxios } from '@dataplace.ai/functions/utils/axios'
import { AnalyticsContext, AuthContext } from '@dataplace.ai/features'
import { ILocation } from '@dataplace.ai/types'
import Geocoder from 'apps/placeme/src/services/Geocoder/Geocoder'
import { deleteTileAction, fetchWorkspaceUsageValue, saveNewRangeAction, saveTileData } from 'apps/placeme/src/features/Analyse/slice/analysisSlice'
import { fetchSubscriptionInfo } from 'apps/placeme/src/features/ChooseLocationReport/chooseLocationSlice'
import { Loader } from 'libs/shared/ui-components/src/atoms'
import { MapVisualizationModal } from '@dataplace.ai/ui-components/molecules'
import { IFeatureCollection } from '@dataplace.ai/ui-components/atoms/MapTile/components/MapOverlays/@types/IFeatureCollection'
import { checkComparedCoinsValue } from 'apps/placeme/src/functions'
import { getWorkspaceIdFromLocalStorage } from '@dataplace.ai/functions/utils'
import { ComparedLocationHeader, LocalizationSelector, LocalizationSelectorWrapper, TileFooter } from '../../../../atoms'
import { ITileData } from '../../../../../slice/@types/ITileData'
import { ENDPOINTS } from '../../../../../../../constants/endpoints'
import { RootState } from '../../../../../../../redux/store'
import { ILocalMarketShareTileData } from './@types/ILocalMarketShareTileData'
import { ReactComponent as HelpCircle } from '../../../../../../../../../../libs/shared/assets/src/lib/icons/dataplace/help-circle.svg'
import { SettingsBox } from '../../../../molecules/SettingsBox'
import { tilesWithoutComparedLocation } from '../../../../molecules/AddAnotherLocationModal/constants'

const StyledTileFormWrapper = styled(TileFormWrapper)(({ theme }) => {
  const { palette } = theme
  return css`
    background-color: ${palette.light.main};
  `
})

const NormalSpan = styled.span(({ theme }) => {
  const { typography } = theme
  return css`
    margin-bottom: 1rem;
    font-size: ${typography.small.pt_13_regular.fontSize};
    font-weight: ${typography.small.pt_13_regular.fontWeight};
    line-height: ${typography.small.pt_13_regular.lineHeight};
  `
})

const StyledHelpCircle = styled(HelpCircle)(({ theme }) => {
  const { palette } = theme
  return css`
    cursor: pointer;
    min-width: 1rem;
    width: 1rem;
    margin-bottom: -0.2rem;

    > path {
      fill: ${palette.blue};
    }`
})

const TooltipWrapper = styled.div(({ theme }) => {
  const {
    palette, typography, corners, shadows,
  } = theme
  return css`
    background-color: rgba(21, 24, 31, 0.8);
    padding: 1rem;
    display: flex;
    flex-direction: column;
    color: ${palette.light.white};
    border-radius: ${corners.default.borderRadius};
    box-shadow: ${shadows.hover.boxShadow};

    span {
      font-size: ${typography.tiny.pt_12_medium_upper.fontSize};
      font-weight: ${typography.tiny.pt_12_medium_upper.fontWeight};
      line-height: ${typography.tiny.pt_12_medium_upper.lineHeight};
      letter-spacing: ${typography.tiny.pt_12_medium_upper.letterSpacing};
      text-transform: ${typography.tiny.pt_12_medium_upper.textTransform};
    }

    ul {
      margin: 1rem 0 1rem 1rem;
    }

    li {
      font-size: ${typography.tiny.pt_12_regular.fontSize};
      font-weight: ${typography.tiny.pt_12_regular.fontWeight};
      line-height: ${typography.tiny.pt_12_regular.lineHeight};
    }

    img {
      max-height: 250px;
      max-width: 250px;
    }
  `
})

const MapWrapper = styled.div(
  () => css`
    margin: 1rem 0;
    width: 100%;
    height: 300px;
  `,
)

const StyledTitleFormSectionSubTitle = styled(TitleFormSectionSubTitle)`
  margin-bottom: 0;
`

const LabelsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`

const LabelItem = styled.div(({ theme }) => {
  const { palette } = theme
  return css`
  display: flex;
  width: 45%;
  color: ${palette.dark.normal};
  margin: 0 8px 30px 0;
  >div{
   display: flex;
   flex-direction: column;
   margin-left: 5px;
  }
`
})

const Color = styled.span<{color: string}>(({ color }) => css`
    height:100%;
    width: 20px;
    border-radius: 6px;
    background-color:${color || '#a1a1a1'};
  `)

const StyledTileFormParagraph = styled(TileFormParagraph)`
    margin-bottom: 20px;
  `

const TooltipContent :React.FC = () => {
  const { t } = useTranslation()
  return (
    <TooltipWrapper>
      <span>{t('placeme.local_market_share_tile.tooltip_title')}</span>
      <ul>
        <li>{t('placeme.local_market_share_tile.list_1')}</li>
        <li>{t('placeme.local_market_share_tile.list_2')}</li>
        <li>{t('placeme.local_market_share_tile.list_3')}</li>
      </ul>
      <img
        alt='map'
        src='assets/images/localMarketExample.svg'
      />
    </TooltipWrapper>
  ) }

export const LocalMarketShareTile:
React.FC<{data: ILocalMarketShareTileData, tileId: string, isExtraPaid?: boolean, }> = ({
  data, tileId, isExtraPaid,
}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [localizations, setLocalizations] = useState<ILocation[]>([])
  const [comparedLocalizations, setComparedLocalizations] = useState<ILocation[]>([])
  const [accepted, setAccepted] = useState<boolean>(false)
  const [currentlyLocalizations, setCurrentlyLocalizations] = useState<ILocation[]>([])
  const [localizationsLoading, setLocalizationsLoading] = useState(false)
  const [userId, setUserId] = useState('')
  const [loading, setLoading] = useState(false)
  const [isMapDisplayed, setIsMapDisplayed] = useState(false)
  const [isComparedMapDisplayed, setIsComparedMapDisplayed] = useState(false)

  const {
    value, currentSubscriptionData, analyseId, comparedAnalyseId,
  } = useSelector((state: RootState) => state.location)
  const {
    values, creditsAmount, comparedLocation,
  } = useSelector((state: RootState) => state.analysis)
  const [token, setToken] = useState('')
  const authContext = useContext(AuthContext)
  const { analytics } = useContext(AnalyticsContext)

  const handleMapOpen = (compared: boolean) => {
    if (compared) setIsComparedMapDisplayed(!isComparedMapDisplayed)
    else setIsMapDisplayed(!isMapDisplayed)
  }

  const fetchTileRange = () => values.find(cat => cat.id === 'potential')?.tiles.find(t => t.id === tileId)?.chosenRange?.catchmentId

  const fetchData = useCallback(async () => {
    const catchmentId = values?.find(c => c.id === 'potential')?.tiles?.find(t =>
      t.id === tileId)?.chosenRange?.catchmentId
    if (accepted && loading) {
      const body = {
        catchmentId,
        addresses: localizations?.map((l, index) => ({
          id: index.toString(),
          address: l.address,
          lat: l.lat,
          lng: l.lng,
        })),
      }
      let saveData
      const axiosInstance = await getAxios({
        errCallbackFn: (e) => {
          saveData = {
            loading: false,
            error: e,
            value: null,
          }
        },
      })
      const response = await axiosInstance.post<ITileData>(ENDPOINTS.LOCAL_MARKET_SHARE_TILE, body)
      if (response) {
        saveData = {
          loading: false,
          error: '',
          value: response.data,
        }
        if (response.status === 204) {
          window?.localStorage.setItem('noDataModal', catchmentId || 'no catchment')
          window?.dispatchEvent(new CustomEvent('noDataModal'))
        }
      }
      setLoading(false)
      setAccepted(false)
      dispatch(saveTileData('potential', tileId, saveData))
      dispatch(fetchSubscriptionInfo(currentSubscriptionData?.value?.subscriptionId || ''))
    }
  }, [accepted, token, currentSubscriptionData])

  const fetchCurrentlyUsedAddresses = useCallback(async () => {
    let saveData
    const axiosInstance = await getAxios({
      errCallbackFn: (e) => {
        saveData = {
          loading: false,
          error: e,
          value: null,
        }
      },
    })
    const response = await axiosInstance.get(
      `/user/${userId}/catchments?workspace_id=${getWorkspaceIdFromLocalStorage()}&filter=coordinates`,
    )

    if (response) {
      saveData = {
        loading: false,
        error: '',
        value: response.data,
      }
    }

    if (saveData?.value?.length) {
      const localizations : ILocation[] = []
      saveData?.value?.forEach((loc: { lat: number; lng: number }) => {
        if (typeof loc?.lat === 'number' && typeof loc?.lng === 'number') {
          const geocoder = new Geocoder()
          let address = ''
          geocoder.reverseGeocode(loc?.lat, loc?.lng).then(async (result) => {
            // eslint-disable-next-line camelcase
            const res = result as { formatted_address: string }[]
            if (result && res.length > 0) {
              address = res[0].formatted_address

              localizations.push({
                placeId: address + loc?.lat + loc?.lng,
                address,
                lat: loc?.lat,
                lng: loc?.lng,
              })
            }
          })
        }
      })
      setCurrentlyLocalizations(localizations)
      setLocalizationsLoading(false)
    }
  }, [token, userId, getWorkspaceIdFromLocalStorage()])
  const handleSave = () => {
    setLoading(true)
    if (!fetchTileRange()) {
      dispatch(saveNewRangeAction(token, authContext.userData.user?.uid || '', 'potential', tileId, {
        id: `${tileId}-1000-line`,
        value: 1000,
        type: 'line',
      }))
    }
  }

  const handleSubmit = () => {
    if (creditsAmount) {
      const chosenRange = values?.find(c => c.id === 'potential')?.tiles?.find(t =>
        t.id === tileId)?.chosenRange
      analytics?.track('Tile Data Generated', {
        location: {
          ...value,
        },
        tile: tileId?.split('-')[0],
        range: {
          type: chosenRange?.type,
          length: chosenRange?.value,
        },
      })
      handleSave()
    }
  }

  const handleDeleteTile = () => {
    const chosenRange = values?.find(c => c.id === 'potential')?.tiles?.find(t =>
      t.id === tileId)?.chosenRange
    analytics?.track('Tile Cancel Button Clicked', {
      location: {
        ...value,
      },
      tile: tileId?.split('-')[0],
      range: {
        type: chosenRange?.type,
        value: chosenRange?.value,
      },
    })
    dispatch(deleteTileAction(token, 'potential', tileId, comparedAnalyseId || analyseId || ''))
  }

  const getLayers = (compared: boolean) => {
    if (data?.value) {
      const items = compared ? data?.value?.comparedLocation : data?.value
      const features : IFeatureCollection['data']['features'] = []

      items?.map?.localMarketShareLayers?.data?.features?.forEach(feature => {
        features.push({
          geometry: feature?.properties?.geometry,
          properties: {
            ...feature?.properties,
            style: {
              ...feature?.properties?.style,
              weight: 1,
              fillOpacity: 0.5,
            },
          },
          type: 'Feature',
        }) })

      items?.localMarketShare?.forEach(item => {
        features.push({
          geometry: {
            coordinates: [item?.lng, item?.lat],
            type: 'Point',
          },
          properties:{
            title: `<div style="max-width:450px; display: flex; flex-direction: column; flex-wrap: wrap;"><span><strong>${item?.address}</strong></span><span>${`${t('placeme.local_market.domination.label')}: <strong>${item?.dominationInTheZone}`}</strong></span><span>${`${t('placeme.local_market.average_share.label')}: <strong>${item?.averageShare}`}</strong></span></div>`,
            pinnedItem: {
              iconAnchor:[10, 10],
              class: 'local_market_pinned-item',
              html: `<span style="display: flex; width: 1.25rem; height: 1.25rem; border-radius: 50%; color: white; background-color: ${item?.color}; align-items: center; justify-content: center;"></span>`,
            },
          },
          type: 'Feature',
        })
      })

      return [{
        id: `local_market_layer_${fetchTileRange()}`,
        layer: {
          data: {
            features,
            type: 'FeatureCollection',
          },
          options: {
            type: 'geojson',
            id: 'local_market_layer_map',
          },
        },
      }]
    }
    return undefined
  }

  useEffect(() => {
    if (!data?.value && accepted && loading) fetchData()
  }, [fetchData, data?.value, accepted, loading])

  useEffect(() => {
    if (token && userId && getWorkspaceIdFromLocalStorage()) setLocalizationsLoading(true)
  }, [token, userId, getWorkspaceIdFromLocalStorage()])

  useEffect(() => {
    if (localizationsLoading) {
      fetchCurrentlyUsedAddresses()
    }
  }, [localizationsLoading])

  useEffect(() => {
    if (fetchTileRange() && loading) {
      setAccepted(true)
    }
  }, [fetchTileRange(), loading])
  useEffect(() => {
    authContext.userData?.user?.getIdToken()?.then(response => {
      setToken(response)
    })
  }, [authContext])

  useEffect(() => {
    if (token.length) {
      dispatch(fetchWorkspaceUsageValue())
    }
  }, [token, data])

  useEffect(() => {
    if (authContext.userData?.user?.uid) setUserId(authContext.userData?.user?.uid)
  }, [authContext])

  return (
    <>
      { loading
        ? <Loader />
        : (
          <StyledTileFormWrapper>
            <TileFormSection>
              <TileFormRowWithData>
                <span>{t('placeme.local_market_share_tile.row_with_data_1_span_1')}</span>
              </TileFormRowWithData>
              <TileFormParagraph>
                {t('placeme.local_market_share_tile.paragraph_1')}
                <Popup
                  closeOnDocumentClick
                  on={['hover', 'focus']}
                  position='right center'
                  trigger={<StyledHelpCircle />}
                >
                  <TooltipContent />
                </Popup>
              </TileFormParagraph>
              {(!data?.value) && (
                <>
                  <>
                    {!data?.value
                    && (
                      <TitleFormSectionSubTitle
                        style={{
                          marginBottom: comparedLocation?.location && comparedLocation?.generatedFromNow ? '1.5rem' : '0',
                        }}
                      >
                        {t('placeme.local_market_share_tile.localization_selector_title')}
                      </TitleFormSectionSubTitle>
                    )}
                    {comparedLocation?.location && comparedLocation?.generatedFromNow
                  && (
                    <ComparedLocationHeader>
                      <h5>{t('placeme.municipality_population.compared_location.header_1')}</h5>
                      {' '}
                      <span>{value?.address}</span>
                    </ComparedLocationHeader>
                  )}
                    <LocalizationSelectorWrapper>
                      {!!data?.value
                    && <NormalSpan>{t('placeme.local_market_share_tile.localization_selector_has_items_title')}</NormalSpan>}
                      {!data?.value
                    && <NormalSpan>{t('placeme.local_market_share_tile.localization_selector_no_items')}</NormalSpan>}
                      <LocalizationSelector
                        disabled={localizations?.length >= 5}
                        instantAdd
                        localizations={currentlyLocalizations}
                        localizationsLoading={localizationsLoading}
                        multiple
                        onChange={setLocalizations}
                        tile={tileId}
                      />

                    </LocalizationSelectorWrapper>
                  </>
                  {/* compared location && generated from now  */}
                  {comparedLocation?.location?.address && comparedLocation?.generatedFromNow
                  && (
                    <>
                      {!data?.value
                  && (
                    <TitleFormSectionSubTitle
                      style={{
                        marginBottom: '0',
                      }}
                    >
                      {t('placeme.local_market_share_tile.localization_selector_title')}
                    </TitleFormSectionSubTitle>
                  )}
                      <ComparedLocationHeader>
                        <h5>{t('placeme.municipality_population.compared_location.header_2')}</h5>
                        {' '}
                        <span>{comparedLocation?.location?.address}</span>
                      </ComparedLocationHeader>
                      <LocalizationSelectorWrapper>
                        {!!data?.value
                  && <NormalSpan>{t('placeme.local_market_share_tile.localization_selector_has_items_title')}</NormalSpan>}
                        {!data?.value
                  && <NormalSpan>{t('placeme.local_market_share_tile.localization_selector_no_items')}</NormalSpan>}
                        <LocalizationSelector
                          disabled={comparedLocalizations?.length >= 5}
                          instantAdd
                          localizations={currentlyLocalizations}
                          localizationsLoading={localizationsLoading}
                          multiple
                          onChange={setComparedLocalizations}
                          tile={tileId}
                        />

                      </LocalizationSelectorWrapper>
                    </>
                  )}
                </>
              )}

              {(data?.value) && (
                <>
                  <>
                    {data?.value?.comparedLocation
                  && (
                    <ComparedLocationHeader>
                      <h5>{t('placeme.municipality_population.compared_location.header_1')}</h5>
                      {' '}
                      <span>{value?.address}</span>
                    </ComparedLocationHeader>
                  )}
                    <SettingsBox
                      noEdit
                      sectionTile='potential'
                      tile={tileId}
                      typeRanges={{
                        addresses: data?.value?.addresses?.map(address => address.address),
                      }}
                    />
                    <StyledTitleFormSectionSubTitle>
                      <span>{t('placeme.local_market_share.map_title')}</span>
                      <LinkWithIconCheckOnMap onClick={() => handleMapOpen(false)} />
                    </StyledTitleFormSectionSubTitle>
                    <MapWrapper>
                      <MapTile
                        dragging
                        height='100%'
                        layers={getLayers(false)}
                        location={value}
                        mapId='example-map-data-tile'
                        pinDisplayed
                        popupHeading={`${t('generic.chosen_location')}:`}
                        popupParagraph={value?.address}
                        showScaleControl
                        width='100%'
                        zoom={14}
                        zoomControl
                      />
                    </MapWrapper>
                    <StyledTitleFormSectionSubTitle>
                      <span>{t('placeme.local_market_share.labels_title')}</span>
                    </StyledTitleFormSectionSubTitle>
                    <StyledTileFormParagraph>
                      {t('placeme.local_market_share.labels_description')}
                    </StyledTileFormParagraph>
                    <LabelsWrapper>
                      { data?.value?.localMarketShare?.map(item => (
                        <LabelItem key={item?.id}>
                          <Color color={item?.color} />
                          <div>
                            <strong>
                              {item?.address}
                            </strong>
                            <span>
                              {t('placeme.local_market.average_share.label')}
                              :
                              {' '}
                              <strong>
                                {(item?.averageShare * 100).toFixed(0)}
                                %
                              </strong>
                            </span>
                            <span>
                              {t('placeme.local_market.domination.label')}
                              :
                              {' '}
                              <strong>{item?.dominationInTheZone}</strong>
                            </span>
                          </div>
                        </LabelItem>
                      ))}
                    </LabelsWrapper>
                  </>

                  {isMapDisplayed
                  && (
                    <MapVisualizationModal
                      isDisplayed={isMapDisplayed}
                      layers={getLayers(false)}
                      location={value}
                      mapId={`time-select-local_market-map-${values?.find(c => c.id === 'potential')?.tiles?.find(t => t.id === tileId)?.chosenRange?.catchmentId}`}
                      setIsDisplay={setIsMapDisplayed}
                      zoom={14}
                    />
                  )}
                  {data?.value?.comparedLocation
                  && (
                    <>
                      <>
                        <ComparedLocationHeader second>
                          <h5>{t('placeme.municipality_population.compared_location.header_2')}</h5>
                          {' '}
                          <span>{comparedLocation?.location?.address}</span>
                        </ComparedLocationHeader>
                        <SettingsBox
                          noEdit
                          sectionTile='potential'
                          tile={tileId}
                          typeRanges={{
                            addresses: data?.value?.comparedLocation?.addresses?.map(address => address.address),
                          }}
                        />
                        <StyledTitleFormSectionSubTitle>
                          <span>{t('placeme.local_market_share.map_title')}</span>
                          <LinkWithIconCheckOnMap onClick={() => handleMapOpen(true)} />
                        </StyledTitleFormSectionSubTitle>
                        <MapWrapper>
                          <MapTile
                            dragging
                            height='100%'
                            layers={getLayers(true)}
                            location={comparedLocation?.location}
                            mapId='example-map-data-tile'
                            pinDisplayed
                            popupHeading={`${t('generic.chosen_location')}:`}
                            popupParagraph={comparedLocation?.location?.address}
                            showScaleControl
                            width='100%'
                            zoom={14}
                            zoomControl
                          />
                        </MapWrapper>
                        <StyledTitleFormSectionSubTitle>
                          <span>{t('placeme.local_market_share.labels_title')}</span>
                        </StyledTitleFormSectionSubTitle>
                        <StyledTileFormParagraph>
                          {t('placeme.local_market_share.labels_description')}
                        </StyledTileFormParagraph>
                        <LabelsWrapper>
                          { data?.value?.comparedLocation?.localMarketShare?.map(item => (
                            <LabelItem key={item?.id}>
                              <Color color={item?.color} />
                              <div>
                                <strong>
                                  {item?.address}
                                </strong>
                                <span>
                                  {t('placeme.local_market.average_share.label')}
                                  :
                                  {' '}
                                  <strong>
                                    {(item?.averageShare * 100).toFixed(0)}
                                    %
                                  </strong>
                                </span>
                                <span>
                                  {t('placeme.local_market.domination.label')}
                                  :
                                  {' '}
                                  <strong>{item?.dominationInTheZone}</strong>
                                </span>
                              </div>
                            </LabelItem>
                          ))}
                        </LabelsWrapper>
                      </>

                      {isComparedMapDisplayed
                  && (
                    <MapVisualizationModal
                      isDisplayed={isComparedMapDisplayed}
                      layers={getLayers(true)}
                      location={value}
                      mapId={`time-select-local_market-map-${values?.find(c => c.id === 'potential')?.tiles?.find(t => t.id === tileId)?.chosenRange?.catchmentId}_compared`}
                      setIsDisplay={setIsComparedMapDisplayed}
                      zoom={14}
                    />
                  )}
                    </>
                  )}
                </>

              )}
            </TileFormSection>

            {(!data?.value) && (
              <TileFooter
                disabled={!localizations?.length}
                isExtraPaid={isExtraPaid}
                isUnlimited={currentSubscriptionData?.value?.planExact?.includes('unlimited') || currentSubscriptionData?.value?.plan === 'white'}
                label={isExtraPaid ? t('generic.apply_and_buy') : t('generic.apply')}
                onAccept={handleSubmit}
                onCancel={handleDeleteTile}
                tile='local_market_share'
                value={(localizations.length * checkComparedCoinsValue(
                  comparedLocation?.generatedFromNow,
                  !!comparedLocation?.location,
                  !tilesWithoutComparedLocation.includes(tileId?.split('-')[0]),
                )).toString()}
              />
            )}

          </StyledTileFormWrapper>
        ) }
    </>
  )
}
