import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { InfoBean } from '@dataplace.ai/ui-components/molecules/InfoBean/InfoBean'
import { CommercialAndServicesData, ResidentialData } from '../@types/IInvestmentsTileData'
import { investmentTypes, TOOLTIP_MAX_WIDTH } from '../utils/constants'

type InvestmentTypes = (typeof investmentTypes)

interface UseGetNoInvestmentTypeBean {
  investmentsData?: ResidentialData | CommercialAndServicesData
  comparedInvestmentsData?: ResidentialData | CommercialAndServicesData
  address?: string
  comparedAddress?: string
  investmentType: InvestmentTypes['residential'] | InvestmentTypes['commercialAndServices']
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function useGetNoInvestmentTypeBean({
  investmentsData, comparedInvestmentsData, investmentType, address, comparedAddress,
}: UseGetNoInvestmentTypeBean) {
  const { t } = useTranslation()

  const translation = useMemo(() => t(`placeme.investments.no_${investmentType}_investments_for`), [investmentType])

  const getNoInvestmentTypeBean = useMemo(() => {
    if ((investmentsData?.list.length && !comparedInvestmentsData)
        || (investmentsData?.list.length && comparedInvestmentsData?.list.length)) {
      return null
    }
    let tooltipText = ''
    if (investmentsData?.list.length && comparedInvestmentsData?.list.length === 0) {
      tooltipText = `${translation}${' '}${comparedAddress}`
    }
    if (comparedInvestmentsData?.list.length && investmentsData?.list.length === 0) {
      tooltipText = `${translation}${' '}${address}`
    }
    if ((investmentsData?.list.length === 0 && comparedInvestmentsData?.list.length === 0)
        || (!investmentsData?.list.length && !comparedInvestmentsData)) {
      tooltipText = t(`placeme.investments.no_planned_${investmentType}_investments`)
    }
    return (
      <InfoBean
        tooltipMaxWidth={TOOLTIP_MAX_WIDTH}
        tooltipText={tooltipText}
      />
    )
  }, [investmentsData, comparedInvestmentsData, investmentType, address, comparedAddress, translation])

  return {
    getNoInvestmentTypeBean,
  }
}

export default useGetNoInvestmentTypeBean
